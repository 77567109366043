import { RouteRecordRaw } from "vue-router";
import { studentRouters } from "./groups";
import { storeRouters } from "./storeGroup";
import { schoolRouters } from "./schoolGroup";
import { systemRouters } from "./systemGroup";

export const Routes: Array<RouteRecordRaw> = [
  schoolRouters,
  studentRouters,
  storeRouters,
  systemRouters,
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () =>
      import(/* webpackChunkName: "701" */ "@/views/404Page.vue"),
  },
];
