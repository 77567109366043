export const CHECK_AUTH = "checkAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
export const CHANGE_EMAIL = "changeEmail";
export const CHANGE_PASSWORD = "changePassword";
export const RESET_PASSWORD = "resetPassword";
export const RESET_PASSWORD_CONFIRM = "resetPasswordConfirm";
export const CONFIRM_CHANGE_EMAIL = "confirmChangeEmail";
export const AUTH_CHECK_VALID_TOKEN = "authCheckValidToken";

// General
export const GENERAL_GET_STUDENT_STATUSES = "generalGetStudentStatuses";
export const GENERAL_GET_LIST_STORE_CATEGORY = "generalGetListStoreCategory";
export const GENERAL_CREATE_BANK = "generalCreateBank";
export const GENERAL_UPDATE_BANK = "generalUpdateBank";
export const GENERAL_DETAIL_BANK = "generalDetailBank";
export const GENERAL_EXPORT_CSV = "generalExportCsv";
export const SEARCH_BANKS = "searchBanks";
export const SEARCH_BANK_BRANCHES = "searchBankBranches";

// Students
export const CHECK_VALID_ACCOUNT = "checkValidAccount";
export const REGISTER_EMAIL = "registerEmail";
export const STUDENT_CHANGE_EMAIL = "studentChangeEmail";
export const STUDENT_CHANGE_PASSWORD = "studentChangePassword";
export const STUDENT_CONFIRM_CHANGE_EMAIL = "studentConfirmChangeEmail";
export const REGISTER_PASSWORD = "registerPassword";
export const CHECK_VALID_TOKEN = "checkValidToken";
export const STUDENT_RESET_PASSWORD = "studentResetPassword";
export const STUDENT_CONFIRM_RESET_PASSWORD = "studentConfirmResetPassword";
export const STUDENT_GET_POINT = "studentGetPoint";
export const STUDENT_GET_LIST = "studentGetList";
export const STUDENT_UPDATE = "studentUpdate";
export const STUDENT_USE_POINT = "studentUsePoint";
export const STUDENT_GET_STORE_LIST = "studentGetStoreList";
export const STUDENT_GET_USED_POINT = "studentGetUsedPoint";
export const STUDENT_DATA_FAQ = "studentDataFaq";
export const STUDENT_SEND_FAQ = "studentSendFaq";
export const STUDENT_DOWNLOAD_CSV = "studentDownloadCsv";

// Schools
export const SCHOOL_CREATE_STUDENT = "schoolCreateStudent";
export const SCHOOL_GET_LIST_STAFF = "schoolGetListStaff";
export const SCHOOL_UPDATE_STUDENT = "schoolUpdateStudent";
export const SCHOOL_GET_LIST_STUDENT = "schoolGetListStudent";
export const SCHOOL_CHANGE_POINT_FOR_STUDENT = "schoolAddOrRefundPointForStudent";
export const SCHOOL_GET_FACULTIES = "schoolGetFaculties";
export const SCHOOL_CREATE_FACULTY = "schoolCreateFaculty";
export const SCHOOL_DELETE_FACULTY = "schoolDeleteFaculty";
export const SCHOOL_GET_DETAIL = "schoolGetDetail";
export const SCHOOL_UPDATE = "schoolUpdate";
export const SCHOOL_GET_STUDENT_STATUSES = "schoolGetStudentStatuses";
export const SCHOOL_UPDATE_STUDENT_STATUSES = "schoolUpdateStudentStatuses";
export const SCHOOL_GET_STUDENT_DETAIL = "schoolGetStudentDetail";
export const SCHOOL_GET_HISTORY_POINT_STUDENT = "schoolGetHistoryPointStudent";
export const SCHOOL_GET_HISTORY_POINT_USED = "schoolGetHistoryPointUsed";
export const SCHOOL_GET_HISTORY_POINT_STATUSES = "schoolGetHistoryPointStatuses";
export const SCHOOL_GET_DETAIL_HISTORY_POINT = "schoolGetDetailHistoryPoint";
export const SCHOOL_GET_LIST_AUTOMATIC_POINT = "schoolGetListAutomaticPoint";
export const SCHOOL_CREATE_AUTOMATIC_POINT = "schoolCreateAutomaticPoint";
export const SCHOOL_GET_DETAIL_AUTOMATIC_POINT = "schoolGetDetailAutomaticPoint";
export const SCHOOL_UPDATE_AUTOMATIC_POINT = "schoolUpdateAutomaticPoint";
export const SCHOOL_DELETE_AUTOMATIC_POINT = "schoolDeleteAutomaticPoint";
export const SCHOOL_IMPORT_STUDENTS = "schoolImportStudents";
export const SCHOOL_DOWNLOAD_CSV = "schoolDownloadCSV";
export const SCHOOL_IMPORT_STAFF = "schoolImportStaff";
export const SCHOOL_CREATE_STAFF = "schoolCreateStaff";
export const SCHOOL_IMPORT_STUDENTS_VALIDATION = "schoolImportStudentsValidation";

// System
export const SYSTEM_GET_LIST_SCHOOL = "systemGetListSchool";
export const SYSTEM_REGISTER_ACCOUNT_MANAGER = "systemRegisterAccountManager";
export const SYSTEM_CREATE_SCHOOL= "systemCreateSchool";
export const SYSTEM_CREATE_ACCOUNT= "systemCreateAccount";
export const SYSTEM_CREATE_STORE_CATEGORY= "systemCreateStoreCategory";
export const SYSTEM_DELETE_STORE_CATEGORY = "systemDeleteStoreCategory";
export const SYSTEM_GET_LIST_POINT_USED = "systemGetListPointUsed";
export const SYSTEM_REFUND_POINT = "systemRefundPoint";
export const SYSTEM_GET_LIST_STORE = "systemGetListStore";
export const SYSTEM_UPDATE_SCHOOL_STATUSES = "systemUpdateSchoolStatuses";
export const SYSTEM_GET_LIST_BILLING = "systemGetListBilling";
export const SYSTEM_GET_DETAIL_BILLING = "systemGetDetailBilling";
export const SYSTEM_GET_BILLING_ID = "systemGetBillingId";
export const SYSTEM_CREATE_AREA= "systemCreateArea";
export const SYSTEM_DELETE_AREA = "systemDeleteArea";
export const SYSTEM_GET_AREA = "systemGetArea";
export const SYSTEM_GET_BILLING_STORE = "systemGetBillStore";
export const SYSTEM_UPDATE_BILLING_STATUS = "systemUpdateBillStatus";
export const SYSTEM_GET_SETTING = "systemGetSetting";
export const SYSTEM_UPDATE_SETTING = "systemUpdateSetting";

//Store
export const STORE_CREATE = "storeCreate";
export const STORE_UPDATE = "storeUpdate";
export const STORE_GET_DETAIL = "storeGetDetail";
export const STORE_GET_PREVIEW = "storeGetPreview";
export const STORE_GET_LIST = "storeGetList";
export const STORE_GET_LIST_STAFF = "storeGetListStaff";
export const STORE_CREATE_STAFF = "storeCreateStaff";
export const STORE_GET_HISTORY_POINT_USED = "storeGetHistoryPointUsed";
export const STORE_REFUND_POINT = "storeRefundPoint";
export const STORE_DOWNLOAD_CSV = "storeDowloadCsv";
export const STORE_IMPORT_STAFF = "storeImportStaff";
export const STORE_POINT_USED = "storePointUsed";
export const STORE_REVENUE_CSV_DOWNLOAD = "storeRevenueCsvDownload";

//Setting
export const SETTING_CREATE = "settingCreate";
export const SETTING_UPDATE = "settingUpdate";
export const SETTING_GET_DETAIL = "settingGetDetail";
export const SETTING_GET_LIST = "settingGetList";
export const SETTING_DELETE = "settingDelete";
