<template>
  <header class="content__header">
    <div class="content__header--left">
      <span>{{ pageTitle }}</span>
    </div>
    <div class="content__header--right position-relative" v-if="displayUser">
      <!-- <change-language :classes="['student-screen mobile-hidden']"></change-language> -->
      <SidebarSp v-show="sidebar"></SidebarSp>
      <img src="@/assets/profile.png" alt="image" class="dropdown-toggle" id="dropdownMenuButton1" role="button" data-bs-toggle="dropdown" aria-expanded="false"/>
      <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li role="button" @click="logout" class="dropdown-item">{{ $t("logout") }}</li>
      </ul>
    </div>
  </header>
</template>

<style lang="scss" scoped>
@import "@/styles/layouts/header.scss";
</style>

<script>
import SidebarSp from "@/components/layouts/SidebarSp";
import ChangeLanguage from "../ChangeLanguage.vue";
import { LOGOUT } from '@/store/actions.type';

export default {
  name: "Header",
  components: { SidebarSp, ChangeLanguage },
  props: {
    pageTitle: String,
    displayUser: Boolean,
    redirect: String,
    sidebar: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    logout() {
      this.$store.dispatch(LOGOUT, this.$props.redirect);
    },
  },
};
</script>
