import { SET_BANKS, SET_BANK_BRANCHES } from './../mutations.type';
import { GeneralSearchBank, GenerateExportCsv, GeneralSearchBankBranch } from './../../services/api/general.service';
import { GENERAL_EXPORT_CSV, SEARCH_BANKS, SEARCH_BANK_BRANCHES } from './../actions.type';
import { GeneralCreateBank, GeneralDetailBank, GeneralGetListStoreCategory, GeneralGetStudentStatuses, GeneralUpdateBank } from "@/services/api/general.service";
import { GENERAL_CREATE_BANK, GENERAL_DETAIL_BANK, GENERAL_GET_LIST_STORE_CATEGORY, GENERAL_GET_STUDENT_STATUSES, GENERAL_UPDATE_BANK } from "../actions.type";
import { SET_DETAIL_BANK, SET_ERROR, SET_LIST_STORE_CATEGORY, SET_LOADING, SET_STUDENT_STATUES, SET_UPDATED_DATA } from "../mutations.type";
import IBank from "@/services/api/models/generals/IBank";

const state = {
	errors: null,
	updatedData: null,
	studentStatuses: [],
	storeCategories: [],
	detailBank: [],
  banks: [],
  bankBranches: [],
};

const getters = {};

const actions = {
	async [GENERAL_GET_STUDENT_STATUSES](
		context: { commit: (arg0: string, arg1: any) => void },
	) {
		try {
			const response = await GeneralGetStudentStatuses();
			context.commit(SET_STUDENT_STATUES, response.data);
			context.commit(SET_ERROR, null);
		} catch (response: any) {
			context.commit(SET_ERROR, response);
		}
	},
	async [GENERAL_GET_LIST_STORE_CATEGORY](
		context: { commit: (arg0: string, arg1: any) => void },
		displayFlag: number | null,
	) {
		try {
			const response = await GeneralGetListStoreCategory(displayFlag);
			context.commit(SET_LIST_STORE_CATEGORY, response.data);
			context.commit(SET_ERROR, null);
		} catch (response: any) {
			context.commit(SET_ERROR, response);
		}
	},
	async [GENERAL_CREATE_BANK](
		context: { commit: (arg0: string, arg1: any) => void },
		body: IBank
	) {
		try {
			context.commit(SET_LOADING, true);
			const response = await GeneralCreateBank(body);
			context.commit(SET_UPDATED_DATA, new Date());
			context.commit(SET_ERROR, null);
			context.commit(SET_LOADING, false);
			return response;
		} catch (response: any) {
			context.commit(SET_LOADING, false);
			context.commit(SET_ERROR, response);
		}
	},
	async [GENERAL_DETAIL_BANK](
		context: { commit: (arg0: string, arg1: any) => void },
		storeId: any
	) {
		try {
			const response = await GeneralDetailBank(storeId);
			context.commit(SET_DETAIL_BANK, response.data);
			context.commit(SET_ERROR, null);
		} catch (response: any) {
			context.commit(SET_ERROR, response);
		}
	},
  async [GENERAL_UPDATE_BANK](
    context: { commit: (arg0: string, arg1: any) => void },
    bank: any
  ) {
    try {
			context.commit(SET_LOADING, true);
      const response = await GeneralUpdateBank(bank.id, bank);
			context.commit(SET_UPDATED_DATA, new Date());
			context.commit(SET_LOADING, false);
			context.commit(SET_ERROR, null);
			return response;
    } catch (response: any) {
			context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [GENERAL_EXPORT_CSV](
    context: { commit: (arg0: string, arg1: any) => void },
    csv: any
  ) {
    try {
      await GenerateExportCsv(csv.url, csv.filename);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },

  async [SEARCH_BANKS](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      const data = await GeneralSearchBank(body);
      context.commit(SET_BANKS, data?.data || []);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      context.commit(SET_BANKS, []);
    }
  },
  async [SEARCH_BANK_BRANCHES](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      const data = await GeneralSearchBankBranch(body);
      context.commit(SET_BANK_BRANCHES, data?.data || []);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      context.commit(SET_BANK_BRANCHES, []);
    }
  },
}

const mutations = {
	[SET_ERROR](state: any, error: any) {
		state.errors = error;
	},
	[SET_UPDATED_DATA](state: any, data: any) {
		state.updatedData = data;
	},
	[SET_STUDENT_STATUES](state: any, responseData: any) {
		state.studentStatuses = responseData;
	},
	[SET_LIST_STORE_CATEGORY](state: any, responseData: any) {
		state.storeCategories = responseData ?? [];
	},
	[SET_DETAIL_BANK](state: any, data: any) {
		state.detailBank = data;
	},
	[SET_BANKS](state: any, data: any) {
		state.banks = data;
	},
	[SET_BANK_BRANCHES](state: any, data: any) {
		state.bankBranches = data;
	},
};

export default {
	state,
	actions,
	mutations,
	getters,
};
