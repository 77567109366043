<template>
  <div
    :class="{
      'sidebar--store': isStore,
      open: sidebarOpen,
    }"
    class="sidebar vh-100"
  >
    <div
      class="btn sidebar--open__btn mt-1 me-1 ms-1"
      @click="sidebarOpen = !sidebarOpen"
    >
      <font-awesome-icon :icon="['fas', 'xmark']" style="color: #ffffff" />
    </div>
    <div class="sidebar--container">
      <div class="sidebar__title text-center">
        <!-- <p class="mb-0">ユーザー Web</p> -->
        <router-link class="sidebar__link" active-class="sidebar__link--active" to="/">
          <img src="@/assets/preme.png" class="sidebar-logo" alt="logo" />
        </router-link>
      </div>
      <router-link
        :class="isStore ? 'sidebar__link--store' : ''"
        class="sidebar__link"
        v-for="(item, index) in sidebars"
        :key="index"
        active-class="sidebar__link--active"
        :to="item.link"
      >
        <div
          class="sidebar__item"
          v-if="item.role.includes(currentUser?.accountTypes?.cd)"
        >
          {{ item.title }}
          <font-awesome-icon
            :icon="['fas', 'chevron-right']"
            class="icon__chevron--right"
          />
        </div>
      </router-link>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "@/styles/layouts/sidebar.scss";
</style>

<script>
import { LOGOUT } from "@/store/actions.type";
import routesNames from "@/router/routesNames";
import emitter from "@/mitt";
import { ACCOUNT_TYPES } from "@/constants";
import { STORE_ROUTER_NAMES } from '@/router/storeGroup';
export default {
  name: "SidebarStore",
  created() {
    emitter.on(`toggleSidebar`, () => {
      this.sidebarOpen = !this.sidebarOpen;
    });
  },
  beforeUnmount() {
    emitter.off("toggleSidebar");
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.currentUser;
    },
    sidebars() {
      return [
        {
          title: this.$t("store.menu_items.point_payment_history_list"),
          link: {name: STORE_ROUTER_NAMES.paymentHistories},
          role: [ACCOUNT_TYPES.STORE_ADMIN, ACCOUNT_TYPES.STORE_USER],
        },
        {
          title: this.$t("store.menu_items.sales_receipt_history"),
          link: {name: STORE_ROUTER_NAMES.salesReceipt},
          role: [ACCOUNT_TYPES.STORE_ADMIN],
        },
        {
          title: this.$t("store.menu_items.add_staff"),
          link: {name: STORE_ROUTER_NAMES.staff},
          role: [ACCOUNT_TYPES.STORE_ADMIN],
        },
        {
          title: this.$t("store.menu_items.store_info_setting"),
          link: {name: STORE_ROUTER_NAMES.info},
          role: [ACCOUNT_TYPES.STORE_ADMIN],
        },
        {
          title: this.$t("store.menu_items.sales_deposit_account_registration"),
          link: {name: STORE_ROUTER_NAMES.depositAccount},
          role: [ACCOUNT_TYPES.STORE_ADMIN],
        },
        {
          title: this.$t("store.menu_items.registration_info_change"),
          link: {name: STORE_ROUTER_NAMES.detail},
          role: [ACCOUNT_TYPES.STORE_ADMIN],
        },
        {
          title: this.$t("store.menu_items.account_setting"),
          link: {name: STORE_ROUTER_NAMES.account},
          role: [ACCOUNT_TYPES.STORE_ADMIN],
        },
      ];
    },
  },
  data() {
    return {
      sidebarOpen: false,
    };
  },
  methods: {
    logout() {
      this.$store.dispatch(LOGOUT, routesNames.store[0]);
    },
  },
  props: {
    isStore: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
