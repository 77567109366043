import { SettingCreate, SettingGetDetail, SettingUpdate } from "@/services/api/setting.service";
import { SET_ERROR, SET_SETTING_DETAIL, SET_UPDATED_DATA } from "../mutations.type";
import { SETTING_CREATE, SETTING_GET_DETAIL, SETTING_UPDATE } from "../actions.type";

const state = {
  errors: null,
  updatedData: null,
  setting: {},
};

const getters = {};

const actions = {
  async [SETTING_CREATE](
    context: { commit: (arg0: string, arg1: any) => void },
    data: any
  ) {
    try {
      await SettingCreate(data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SETTING_UPDATE](
    context: { commit: (arg0: string, arg1: any) => void },
    data: any
  ) {
    try {
      await SettingUpdate(data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [SETTING_GET_DETAIL](
    context: { commit: (arg0: string, arg1: any) => void },
    storeId: any
  ) {
    try {
      const { data } = await SettingGetDetail(storeId);
      context.commit(SET_SETTING_DETAIL, data);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
}

const mutations = {
  [SET_ERROR](state: any, error: any) {
    state.errors = error;
  },
  [SET_UPDATED_DATA](state: any, data: any) {
    state.updatedData = data;
  },
  [SET_SETTING_DETAIL](state: any, responseData: any) {
    state.setting = responseData;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
