<template>
  <nav class="navbar">
    <div class="container-fluid">
      <button
        class="navbar-toggler sidebar--open__btn"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasNavbar"
        aria-controls="offcanvasNavbar"
        aria-label="Toggle navigation"
      >
        <font-awesome-icon :icon="['fas', 'bars']" />
      </button>
      <div
        :class="{
          'sidebar--store': isStore,
          'sidebar--system': isSystem,
        }"
        class="offcanvas offcanvas-end sidebar"
        tabindex="-1"
        id="offcanvasNavbar"
        aria-labelledby="offcanvasNavbarLabel"
      >
        <div class="offcanvas-header">
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
          <!-- <div class="change-language-btn">
            <change-language></change-language>
          </div> -->
        </div>
        <div class="offcanvas-body pt-0">
          <div class="sidebar--container">
            <div class="sidebar__title text-center">
              <router-link
                class="sidebar__link pt-0"
                active-class="sidebar__link--active"
                v-for="(item, index) in menuTitle[$route.meta.routeRole]"
                :key="index"
                :to="item.link"
              >
                <img src="@/assets/preme.png" class="sidebar-logo mb-3" alt="logo" />
              </router-link>
            </div>
            <router-link
              :class="{
                'sidebar__link--store': isStore,
                'sidebar__link--system': isSystem,
              }"
              class="sidebar__link"
              active-class="sidebar__link--active"
              v-for="(item, index) in menuItems[$route.meta.routeRole]"
              :key="index"
              :to="item.link"
            >
              <div
                class="sidebar__item"
                v-if="
                  !isStore ||
                  (isStore && item.role.includes(currentUser?.accountTypes?.cd))
                "
              >
                {{ item.title }}
                <font-awesome-icon
                  :icon="['fas', 'chevron-right']"
                  class="icon__chevron--right"
                />
              </div>
            </router-link>
            <router-link
              v-if="isSchool"
              class="sidebar__link"
              active-class="sidebar__link--active"
              :to="{ name: SCHOOL_ROUTER_NAMES.account }"
              :class="{
                'sidebar__link--active': [
                  SCHOOL_ROUTER_NAMES.account,
                  SCHOOL_ROUTER_NAMES.emailChange,
                  SCHOOL_ROUTER_NAMES.passwordChange,
                ].includes($route.name),
              }"
            >
              <div class="sidebar__item">
                {{ $t("store.menu_items.account_setting") }}
                <font-awesome-icon
                  :icon="['fas', 'chevron-right']"
                  class="icon__chevron--right"
                />
              </div>
            </router-link>
            <!-- <div class="sidebar__item text-white" @click="logout">
              {{ $t("logout") }}
              <font-awesome-icon
                :icon="['fas', 'chevron-right']"
                class="icon__chevron--right"
              />
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
@import "@/styles/layouts/sidebar-sp.scss";

.change-language-btn {
  min-height: 40px;
}
</style>

<script>
import { LOGOUT } from "@/store/actions.type";
import routesNames from "@/router/routesNames";
import { ACCOUNT_TYPES, ROUTE_ROLES } from "@/constants";
import ChangeLanguage from "../ChangeLanguage.vue";
import { STORE_ROUTER_NAMES } from "@/router/storeGroup";
import { SCHOOL_ROUTER_NAMES } from "@/router/schoolGroup";
import { SYSTEM_ROUTER_NAMES } from "@/router/systemGroup";

export default {
  components: { ChangeLanguage },
  name: "SidebarSp",
  setup() {
    return { ROUTE_ROLES, SCHOOL_ROUTER_NAMES };
  },
  computed: {
    routeRole() {
      return this.$route.meta.routeRole;
    },
    currentUser() {
      return this.$store.state.auth.currentUser;
    },
    isSchool() {
      return this.routeRole === ROUTE_ROLES.SCHOOL;
    },
    isStore() {
      return this.routeRole === ROUTE_ROLES.STORE;
    },
    isSystem() {
      return this.routeRole === ROUTE_ROLES.SYSTEM;
    },
    menuItems() {
      return {
        [ROUTE_ROLES.SCHOOL]: [
          {
            title: this.$t("school.menu_items.list"),
            link: { name: SCHOOL_ROUTER_NAMES.studentAccounts },
          },
          {
            title: this.$t("school.menu_items.issue"),
            link: { name: SCHOOL_ROUTER_NAMES.students },
          },
          {
            title: this.$t("school.menu_items.staff"),
            link: { name: SCHOOL_ROUTER_NAMES.staff },
          },
          {
            title: this.$t("school.menu_items.grant_point_student"),
            link: { name: SCHOOL_ROUTER_NAMES.pointAwarded },
          },
          {
            title: this.$t("school.menu_items.point_auto_grant"),
            link: { name: SCHOOL_ROUTER_NAMES.automaticPointAwarded },
          },
          {
            title: this.$t("school.menu_items.point_grant_history"),
            link: { name: SCHOOL_ROUTER_NAMES.pointHistories },
          },
          {
            title: this.$t("school.menu_items.point_use_history"),
            link: { name: SCHOOL_ROUTER_NAMES.pointUsed },
          },
          {
            title: this.$t("school.menu_items.department_setting"),
            link: { name: SCHOOL_ROUTER_NAMES.faculty },
          },
          {
            title: this.$t("student.menu_items.info_change"),
            link: { name: SCHOOL_ROUTER_NAMES.info },
          },
        ],
        [ROUTE_ROLES.STORE]: [
          {
            title: this.$t("store.menu_items.point_payment_history_list"),
            link: { name: STORE_ROUTER_NAMES.paymentHistories },
            role: [ACCOUNT_TYPES.STORE_ADMIN, ACCOUNT_TYPES.STORE_USER],
          },
          {
            title: this.$t("store.menu_items.sales_receipt_history"),
            link: { name: STORE_ROUTER_NAMES.salesReceipt },
            role: [ACCOUNT_TYPES.STORE_ADMIN, ACCOUNT_TYPES.STORE_USER],
          },
          {
            title: this.$t("store.menu_items.add_staff"),
            link: { name: STORE_ROUTER_NAMES.staff },
            role: [ACCOUNT_TYPES.STORE_ADMIN],
          },
          {
            title: this.$t("store.menu_items.store_info_setting"),
            link: { name: STORE_ROUTER_NAMES.info },
            role: [ACCOUNT_TYPES.STORE_ADMIN],
          },
          {
            title: this.$t("store.menu_items.sales_deposit_account_registration"),
            link: { name: STORE_ROUTER_NAMES.depositAccount },
            role: [ACCOUNT_TYPES.STORE_ADMIN],
          },
          {
            title: this.$t("store.menu_items.registration_info_change"),
            link: { name: STORE_ROUTER_NAMES.detail },
            role: [ACCOUNT_TYPES.STORE_ADMIN],
          },
          {
            title: this.$t("store.menu_items.account_setting"),
            link: { name: STORE_ROUTER_NAMES.account },
            role: [ACCOUNT_TYPES.STORE_ADMIN],
          },
        ],
        [ROUTE_ROLES.SYSTEM]: [
          {
            title: this.$t("system.menu_items.university_account_list"),
            link: { name: SYSTEM_ROUTER_NAMES.schoolAccounts },
          },
          {
            title: this.$t("system.menu_items.university_account_issue"),
            link: { name: SYSTEM_ROUTER_NAMES.createSchool },
          },
          {
            title: this.$t("system.menu_items.university_billing_list"),
            link: { name: SYSTEM_ROUTER_NAMES.billingList },
          },
          {
            title: this.$t("system.menu_items.student_account_list"),
            link: { name: SYSTEM_ROUTER_NAMES.students },
          },
          {
            title: this.$t("system.menu_items.restaurant_list"),
            link: { name: SYSTEM_ROUTER_NAMES.stores },
          },
          {
            title: this.$t("system.menu_items.restaurant_account_issue"),
            link: { name: SYSTEM_ROUTER_NAMES.createStore },
          },
          {
            title: this.$t("system.menu_items.restaurant_category_setting"),
            link: { name: SYSTEM_ROUTER_NAMES.storeCategories },
          },
          {
            title: this.$t("school.menu_items.point_use_history"),
            link: { name: SYSTEM_ROUTER_NAMES.pointUsageHistories },
          },
          {
            title: this.$t("system.menu_items.monthly_sales_deposit_list"),
            link: { name: SYSTEM_ROUTER_NAMES.salesPayment },
          },
          {
            title: this.$t("system.menu_items.master_setting"),
            link: { name: SYSTEM_ROUTER_NAMES.account },
          },
          // {
          //   title: this.$t("system.menu_items.management_account"),
          //   link: "/701",
          // },
          {
            title: this.$t("system.menu_items.management_area"),
            link: { name: SYSTEM_ROUTER_NAMES.areas },
          },
          {
            title: "マスタ設定",
            link: { name: SYSTEM_ROUTER_NAMES.setting },
          },
        ],
      };
    },
  },
  data() {
    return {
      sidebarOpen: false,
      menuTitle: {
        [ROUTE_ROLES.SCHOOL]: [
          {
            title: this.$t("student.menu_items.my_page"),
            link: { name: SCHOOL_ROUTER_NAMES.home },
          },
        ],
        [ROUTE_ROLES.STORE]: [
          {
            title: "ロゴスペース",
            link: { name: STORE_ROUTER_NAMES.home },
          },
        ],
        [ROUTE_ROLES.SYSTEM]: [
          {
            title: "ロゴスペース",
            link: { name: SYSTEM_ROUTER_NAMES.home },
          },
        ],
      },
    };
  },
  methods: {
    logout() {
      this.$store.dispatch(LOGOUT, routesNames.school[0]);
    },
  },
};
</script>
