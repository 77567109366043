import { IStudentValidRequest } from "@/services/api/models";
import ApiInstance from "@/services/api/axiosBase";
import { IStudentRegisterEmailPassword } from "./models/students/IStudent";
import { PATH_URL } from "@/constants";

export const StudentCheckValidRegister = (body: IStudentValidRequest) => {
  return ApiInstance.post(`${PATH_URL.STUDENT}/auth/valid-register`, body);
};

export const StudentRegisterEmail = (body: IStudentRegisterEmailPassword) => {
  return ApiInstance.post(`${PATH_URL.STUDENT}/auth/register-email`, body);
};

export const StudentRegisterPassword = (
  body: IStudentRegisterEmailPassword
) => {
  return ApiInstance.post(`${PATH_URL.STUDENT}/auth/register-password`, body);
};

export const StudentCheckValidToken = (body: { token: string }) => {
  return ApiInstance.get(`${PATH_URL.STUDENT}/auth/valid-token?token=${body.token}`);
};

export const StudentChangeEmail = (body: { currentEmail: string, newEmail: string }) => {
  return ApiInstance.post(`${PATH_URL.STUDENT}/change-email`, body);
};

export const StudentConfirmChangeEmail = (token: string) => {
  return ApiInstance.post(`${PATH_URL.STUDENT}/change-email/confirm`, token);
};

export const StudentChangePassword = (body: { currentEmail: string, currentPassword: string, newPassword: string }) => {
  return ApiInstance.post(`${PATH_URL.STUDENT}/change-password`, body);
};

export const StudentResetPassword = (body: { currentEmail: string }) => {
  return ApiInstance.post(`${PATH_URL.STUDENT}/reset-password`, body);
};

export const StudentConfirmResetPassword = (body: { newPassword: string, token: string }) => {
  return ApiInstance.post(`${PATH_URL.STUDENT}/reset-password/confirm`, body);
};

export const StudentGetPoint = (query: any) => {
  let params = new URLSearchParams(query).toString();
  if (params) {
    params = "?" + params;
  } else {
    params = "";
  }
  return ApiInstance.get(`${PATH_URL.STUDENT}/get-point` + params);
};

export const StudentGetList = (query: any) => {
  let params = new URLSearchParams(query).toString();
  if (params) {
    params = "?" + params;
  } else {
    params = "";
  }
  return ApiInstance.get(`${PATH_URL.STUDENT}/list` + params);
};

export const StudentUpdate = (body: any) => {
  return ApiInstance.post(`${PATH_URL.STUDENT}/update`, body);
};

export const StudentUsePoint = (body: any) => {
  return ApiInstance.post(`${PATH_URL.STUDENT}/use-point`, body);
};

export const StudentStoreList = (query: any) => {
  let params = new URLSearchParams(query).toString();
  if (params) {
    params = "?" + params;
  } else {
    params = "";
  }
  return ApiInstance.get(`${PATH_URL.STUDENT}/store/list` + params);
};

export const StudentGetUsedPoint = (id: any) => {
  return ApiInstance.get(`${PATH_URL.STUDENT}/get-used-point/${id}`);
};

export const StudentSendFaq = (data: any) => {
  return ApiInstance.post(`${PATH_URL.STUDENT}/send-faq`, data);
};

export const StudentDownloadCSV = () => {
  ApiInstance.get(`${PATH_URL.STUDENT}/download-csv`, { responseType: 'blob' }).then((response: any) => {
    const href = window.URL.createObjectURL(response);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', '生徒作成フォーム.csv');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};
