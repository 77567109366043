<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: 'PassThrough',
};
</script>
<style scoped>
/* Component styles */
</style>
