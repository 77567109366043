import { mapGetters } from 'vuex'
import * as helpers from '@/utils/Helper'

export default {

  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getErrors',
    ]),

    currentUser() {
      return this.getCurrentUser;
    },
    errors() {
      return this.getErrors;
    }
  },
  methods: {
    ...helpers
  },
}
