import PassThrough from '@/components/layouts/PassThrough.vue';
import { ACCOUNT_TYPES } from '@/constants';
import RoutesNames from '@/router/routesNames';
const STUDENT_ROUTER_NAMES = {
  home: RoutesNames.student[1],
  login: RoutesNames.student[0],
  menu: "menu",
  pointUsedHistories: "pointUsedHistories",
  account: "account",
  info: "info",
  notification: "notification",
  term: "term",
  policy: "policy",
  inquiry: "inquiry",
  inquiryConfirm: "inquiryConfirm",
  inquirySent: "inquirySent",
  register: "register",
  registerEmail: "registerEmail",
  registerEmailConfirm: "registerEmailConfirm",
  registerEmailExpired: "registerEmailExpired",
  registerEmailUsed: "registerEmailUsed",
  registerPassword: "registerPassword",
  registerCompleted: "registerCompleted",
  resetPassword: "resetPassword",
  resetPasswordSuccess: "resetPasswordSuccess",
  resetPasswordChangePassword: "resetPasswordChangePassword",
  resetPasswordCompleted: "resetPasswordCompleted",
  resetPasswordLinkExpired: "resetPasswordLinkExpired",
  resetPasswordLinkUsed: "resetPasswordLinkUsed",
  emailChange: "emailChange",
  emailChangeSent: "emailChangeSent",
  emailChangeCompleted: "emailChangeCompleted",
  emailChangeLinkExpired: "emailChangeLinkExpired",
  emailChangeLinkUsed: "emailChangeLinkUsed",
  passwordChange: "passwordChange",
  usePoint: "usePoint",
  usePointConfirm: "usePointConfirm",
  usePointSuccess: "usePointSuccess",
  storeList: "storeList",
  storeListSearch: "storeListSearch",
  storeListSearchResult: "storeListSearchResult",
  storeDetail: "storeDetail",
}

const studentRouters = {
  path: "/student",
  name: "student_pages",
  component: PassThrough,
  children: [
    {
      path: "/",
      meta: {
        anonymousOnly: true,
      },
      component: () =>
        import(/* webpackChunkName: "211" */ "@/views/students/auth/View211.vue"),
    },
    {
      path: "",
      name: STUDENT_ROUTER_NAMES.login,
      meta: {
        anonymousOnly: true,
      },
      component: () =>
        import(/* webpackChunkName: "211" */ "@/views/students/auth/View211.vue"),
    },
    {
      path: "menu",
      name: STUDENT_ROUTER_NAMES.menu,
      meta: {
        requiresAuth: true,
        redirect: STUDENT_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STUDENT],
      },
      component: () =>
        import(
          /* webpackChunkName: "231" */ "@/views/students/pages/View231.vue"
        ),
    },
    {
      path: "home",
      name: STUDENT_ROUTER_NAMES.home,
      meta: {
        requiresAuth: true,
        redirect: STUDENT_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STUDENT],
      },
      component: () =>
        import(
          /* webpackChunkName: "271" */ "@/views/students/pages/View271.vue"
        ),
    },
    {
      path: "point-used-histories",
      name: STUDENT_ROUTER_NAMES.pointUsedHistories,
      meta: {
        requiresAuth: true,
        redirect: STUDENT_ROUTER_NAMES.login,
        accountTypes: [ACCOUNT_TYPES.STUDENT],
      },
      component: () =>
        import(
          /* webpackChunkName: "272" */ "@/views/students/pages/View272.vue"
        ),
    },
    {
      path: "account",
      name: STUDENT_ROUTER_NAMES.account,
      meta: {
        redirect: STUDENT_ROUTER_NAMES.login,
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.STUDENT],
      },
      component: () =>
        import(
          /* webpackChunkName: "251" */ "@/views/students/pages/View251.vue"
        ),
    },
    {
      path: "info",
      name: STUDENT_ROUTER_NAMES.info,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.student[0],
        accountTypes: [ACCOUNT_TYPES.STUDENT],
      },
      component: () =>
        import(
          /* webpackChunkName: "252" */ "@/views/students/pages/View252.vue"
        ),
    },
    {
      path: "notification",
      name: STUDENT_ROUTER_NAMES.notification,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.student[0],
        accountTypes: [ACCOUNT_TYPES.STUDENT],
      },
      component: () =>
        import(
          /* webpackChunkName: "261" */ "@/views/students/pages/View261.vue"
        ),
    },
    {
      path: "term",
      name: STUDENT_ROUTER_NAMES.term,
      meta: {
        public: true,
      },
      component: () =>
        import(/* webpackChunkName: "202" */ "@/views/students/auth/View202.vue"),
    },
    {
      path: "policy",
      name: STUDENT_ROUTER_NAMES.policy,
      meta: {
        public: true,
      },
      component: () =>
        import(/* webpackChunkName: "203" */ "@/views/students/auth/View203.vue"),
    },
    {
      path: "inquiry",
      name: STUDENT_ROUTER_NAMES.inquiry,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.student[0],
        accountTypes: [ACCOUNT_TYPES.STUDENT],
      },
      component: () =>
        import(
          /* webpackChunkName: "232" */ "@/views/students/pages/View232.vue"
        ),
    },
    {
      path: "register",
      name: STUDENT_ROUTER_NAMES.register,
      meta: {
        redirect: RoutesNames.student[1],
        anonymousOnly: true,
      },
      component: () =>
        import(/* webpackChunkName: "201" */ "@/views/students/auth/View201.vue"),
    },
    {
      path: "register/email",
      name: STUDENT_ROUTER_NAMES.registerEmail,
      meta: {
        redirect: RoutesNames.student[1],
        anonymousOnly: true,
      },
      component: () =>
        import(/* webpackChunkName: "204" */ "@/views/students/auth/View204.vue"),
    },
    {
      path: "register/email-confirm",
      name: STUDENT_ROUTER_NAMES.registerEmailConfirm,
      meta: {
        redirect: RoutesNames.student[1],
        anonymousOnly: true,
      },
      component: () =>
        import(/* webpackChunkName: "205" */ "@/views/students/auth/View205.vue"),
    },
    {
      path: "register/email-expired",
      name: STUDENT_ROUTER_NAMES.registerEmailExpired,
      meta: {
        redirect: RoutesNames.student[1],
        anonymousOnly: true,
      },
      component: () =>
        import(/* webpackChunkName: "206" */ "@/views/students/auth/View206.vue"),
    },
    {
      path: "register/email-used",
      name: STUDENT_ROUTER_NAMES.registerEmailUsed,
      meta: {
        redirect: RoutesNames.student[1],
        anonymousOnly: true,
      },
      component: () =>
        import(/* webpackChunkName: "207" */ "@/views/students/auth/View207.vue"),
    },
    {
      path: "register/password",
      name: STUDENT_ROUTER_NAMES.registerPassword,
      meta: {
        redirect: RoutesNames.student[1],
        anonymousOnly: true,
      },
      component: () =>
        import(/* webpackChunkName: "208" */ "@/views/students/auth/View208.vue"),
    },
    {
      path: "register/completed",
      name: STUDENT_ROUTER_NAMES.registerCompleted,
      meta: {
        redirect: RoutesNames.student[1],
        anonymousOnly: true,
      },
      component: () =>
        import(/* webpackChunkName: "209" */ "@/views/students/auth/View209.vue"),
    },
    {
      path: "reset-password",
      name: STUDENT_ROUTER_NAMES.resetPassword,
      meta: {
        redirect: RoutesNames.student[1],
        anonymousOnly: true,
      },
      component: () =>
        import(/* webpackChunkName: "212" */ "@/views/students/auth/View212.vue"),
    },
    {
      path: "reset-password/success",
      name: STUDENT_ROUTER_NAMES.resetPasswordSuccess,
      meta: {
        redirect: RoutesNames.student[1],
        anonymousOnly: true,
      },
      component: () =>
        import(/* webpackChunkName: "213" */ "@/views/students/auth/View213.vue"),
    },
    {
      path: "reset-password/change-password",
      name: STUDENT_ROUTER_NAMES.resetPasswordChangePassword,
      meta: {
        redirect: RoutesNames.student[1],
        anonymousOnly: true,
      },
      component: () =>
        import(/* webpackChunkName: "214" */ "@/views/students/auth/View214.vue"),
    },
    {
      path: "reset-password/completed",
      name: STUDENT_ROUTER_NAMES.resetPasswordCompleted,
      meta: {
        redirect: RoutesNames.student[1],
        anonymousOnly: true,
      },
      component: () =>
        import(/* webpackChunkName: "215" */ "@/views/students/auth/View215.vue"),
    },
    {
      path: "reset-password/link-expired",
      name: STUDENT_ROUTER_NAMES.resetPasswordLinkExpired,
      meta: {
        redirect: RoutesNames.student[1],
        anonymousOnly: true,
      },
      component: () =>
        import(/* webpackChunkName: "216" */ "@/views/students/auth/View216.vue"),
    },
    {
      path: "reset-password/link-used",
      name: STUDENT_ROUTER_NAMES.resetPasswordLinkUsed,
      meta: {
        redirect: RoutesNames.student[1],
        anonymousOnly: true,
      },
      component: () =>
        import(/* webpackChunkName: "217" */ "@/views/students/auth/View217.vue"),
    },
    {
      path: "inquiry/confirm",
      name: STUDENT_ROUTER_NAMES.inquiryConfirm,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.student[0],
        accountTypes: [ACCOUNT_TYPES.STUDENT],
      },
      component: () =>
        import(
          /* webpackChunkName: "233" */ "@/views/students/pages/View233.vue"
        ),
    },
    {
      path: "inquiry/sent",
      name: STUDENT_ROUTER_NAMES.inquirySent,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.student[0],
        accountTypes: [ACCOUNT_TYPES.STUDENT],
      },
      component: () =>
        import(
          /* webpackChunkName: "234" */ "@/views/students/pages/View234.vue"
        ),
    },
    {
      path: "email-change",
      name: STUDENT_ROUTER_NAMES.emailChange,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.student[0],
        accountTypes: [ACCOUNT_TYPES.STUDENT],
      },
      component: () =>
        import(
          /* webpackChunkName: "253" */ "@/views/students/pages/View253.vue"
        ),
    },
    {
      path: "email-change/sent",
      name: STUDENT_ROUTER_NAMES.emailChangeSent,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.student[0],
        accountTypes: [ACCOUNT_TYPES.STUDENT],
      },
      component: () =>
        import(
          /* webpackChunkName: "254" */ "@/views/students/pages/View254.vue"
        ),
    },
    {
      path: "email-change/completed",
      name: STUDENT_ROUTER_NAMES.emailChangeCompleted,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.student[0],
        accountTypes: [ACCOUNT_TYPES.STUDENT],
      },
      component: () =>
        import(
          /* webpackChunkName: "255" */ "@/views/students/pages/View255.vue"
        ),
    },
    {
      path: "email-change/link-expired",
      name: STUDENT_ROUTER_NAMES.emailChangeLinkExpired,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.student[0],
        accountTypes: [ACCOUNT_TYPES.STUDENT],
      },
      component: () =>
        import(
          /* webpackChunkName: "256" */ "@/views/students/pages/View256.vue"
        ),
    },
    {
      path: "email-change/link-used",
      name: STUDENT_ROUTER_NAMES.emailChangeLinkUsed,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.student[0],
        accountTypes: [ACCOUNT_TYPES.STUDENT],
      },
      component: () =>
        import(
          /* webpackChunkName: "257" */ "@/views/students/pages/View257.vue"
        ),
    },
    {
      path: "password-change",
      name: STUDENT_ROUTER_NAMES.passwordChange,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.student[0],
        accountTypes: [ACCOUNT_TYPES.STUDENT],
      },
      component: () =>
        import(
          /* webpackChunkName: "258" */ "@/views/students/pages/View258.vue"
        ),
    },
    {
      path: "use-point/:storeId",
      name: STUDENT_ROUTER_NAMES.usePoint,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.student[0],
        accountTypes: [ACCOUNT_TYPES.STUDENT],
      },
      component: () =>
        import(
          /* webpackChunkName: "274" */ "@/views/students/pages/View274.vue"
        ),
    },
    {
      path: "use-point/:storeId/confirm",
      name: STUDENT_ROUTER_NAMES.usePointConfirm,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.student[0],
        accountTypes: [ACCOUNT_TYPES.STUDENT],
      },
      component: () =>
        import(
          /* webpackChunkName: "275" */ "@/views/students/pages/View275.vue"
        ),
    },
    {
      path: "use-point/:id/success",
      name: STUDENT_ROUTER_NAMES.usePointSuccess,
      meta: {
        redirect: RoutesNames.student[0],
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.STUDENT],
      },
      component: () =>
        import(
          /* webpackChunkName: "276" */ "@/views/students/pages/View276.vue"
        ),
    },
    {
      path: "store-list",
      name: STUDENT_ROUTER_NAMES.storeList,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.student[0],
        accountTypes: [ACCOUNT_TYPES.STUDENT],
      },
      component: () =>
        import(
          /* webpackChunkName: "291" */ "@/views/students/pages/View291.vue"
        ),
    },
    {
      path: "store-list/search",
      name: STUDENT_ROUTER_NAMES.storeListSearch,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.student[0],
        accountTypes: [ACCOUNT_TYPES.STUDENT],
      },
      component: () =>
        import(
          /* webpackChunkName: "292" */ "@/views/students/pages/View292.vue"
        ),
    },
    {
      path: "store-list/search/result",
      name: STUDENT_ROUTER_NAMES.storeListSearchResult,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.student[0],
        accountTypes: [ACCOUNT_TYPES.STUDENT],
      },
      component: () =>
        import(
          /* webpackChunkName: "293" */ "@/views/students/pages/View293.vue"
        ),
    },
    {
      path: "store/:id",
      name: STUDENT_ROUTER_NAMES.storeDetail,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.student[0],
        accountTypes: [ACCOUNT_TYPES.STUDENT],
      },
      component: () =>
        import(
          /* webpackChunkName: "294" */ "@/views/students/pages/View294.vue"
        ),
    },
  ]
}

export {
  studentRouters,
  STUDENT_ROUTER_NAMES
};
