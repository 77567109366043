import { SET_HISTORY_POINT_USED_STORE, SET_LIST_STAFF, SET_LIST_STORE, SET_STORE_DETAIL } from './../mutations.type';
import { STORE_DOWNLOAD_CSV, STORE_CREATE, STORE_CREATE_STAFF, STORE_GET_DETAIL, STORE_GET_HISTORY_POINT_USED, STORE_GET_LIST, STORE_GET_LIST_STAFF, STORE_REFUND_POINT, STORE_UPDATE, STORE_POINT_USED, STORE_IMPORT_STAFF, STORE_REVENUE_CSV_DOWNLOAD, STORE_GET_PREVIEW } from './../actions.type';
import { SET_ERROR, SET_LOADING, SET_UPDATED_DATA } from "../mutations.type";
import { StoreCreate, StoreCreateStaff, StoreDownloadCSV, StoreGetDetail, StoreGetHistoryPointUsed, StoreGetList, StoreGetListStaff, StoreGetPointReceived, StoreGetPreview, StoreImportStaff, StoreRefundPoint, StoreRevenueCsvDownload, StoreUpdate } from '@/services/api/store.service';
import router from "@/router";
import { STORE_ROUTER_NAMES } from '@/router/storeGroup';

const state = {
  errors: null,
  loading: false,
  updatedData: null,
  store: {},
  listStores: [],
  listStaffStores: [],
  historyPointUsedStore: {},
};

const getters = {};

const actions = {
  async [STORE_CREATE](
    context: { commit: (arg0: string, arg1: any) => void },
    data: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await StoreCreate(data);
      context.commit(SET_ERROR, null);
      context.commit(SET_LOADING, false);
      router.push({
        name: STORE_ROUTER_NAMES.home
      });
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [STORE_CREATE_STAFF](
    context: { commit: (arg0: string, arg1: any) => void },
    data: any
  ) {
    try {
      await StoreCreateStaff(data);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [STORE_UPDATE](
    context: { commit: (arg0: string, arg1: any) => void },
    data: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await StoreUpdate(data.data);
      context.commit(SET_ERROR, null);
      context.commit(SET_LOADING, false);
      router.push(data.redirectPath || {name: STORE_ROUTER_NAMES.home});
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [STORE_IMPORT_STAFF](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await StoreImportStaff(body);
      context.commit(SET_LOADING, false);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [STORE_DOWNLOAD_CSV](
    context: { commit: (arg0: string, arg1: any) => void },
  ) {
    try {
      const response = await StoreDownloadCSV();
      context.commit(SET_ERROR, null);
      return response;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [STORE_GET_DETAIL](
    context: { commit: (arg0: string, arg1: any) => void },
    storeId: any
  ) {
    try {
      const { data } = await StoreGetDetail(storeId);
      context.commit(SET_STORE_DETAIL, data);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [STORE_GET_PREVIEW](
    context: { commit: (arg0: string, arg1: any) => void },
    storeId: any
  ) {
    try {
      const { data } = await StoreGetPreview(storeId);
      context.commit(SET_STORE_DETAIL, data);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [STORE_GET_LIST](
    context: { commit: (arg0: string, arg1: any) => void },
    query: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      const response = await StoreGetList(query);
      context.commit(SET_LIST_STORE, response.data);
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [STORE_GET_LIST_STAFF](
    context: { commit: (arg0: string, arg1: any) => void },
    query: any
  ) {
    try {
      const response = await StoreGetListStaff(query);
      context.commit(SET_LIST_STAFF, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [STORE_GET_HISTORY_POINT_USED](
    context: { commit: (arg0: string, arg1: any) => void },
    query: any
  ) {
    try {
      const response = await StoreGetHistoryPointUsed(query);
      context.commit(SET_HISTORY_POINT_USED_STORE, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [STORE_REFUND_POINT](
    context: { commit: (arg0: string, arg1: any) => void },
    body: object
  ) {
    try {
      context.commit(SET_LOADING, true);
      const { data } = await StoreRefundPoint(body);
      context.commit(SET_LOADING, false);
      context.commit(SET_UPDATED_DATA, new Date());
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      context.commit(SET_LOADING, false);
    }
  },
  async [STORE_POINT_USED](
    context: { commit: (arg0: string, arg1: any) => void },
    body: object
  ) {
    try {
      context.commit(SET_LOADING, true);
      const { data } = await StoreGetPointReceived(body);
      context.commit(SET_HISTORY_POINT_USED_STORE, data);
      context.commit(SET_LOADING, false);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      context.commit(SET_LOADING, false);
    }
  },
  async [STORE_REVENUE_CSV_DOWNLOAD](context: { commit: (arg0: string, arg1: any) => void }) {
    try {
      context.commit(SET_LOADING, true);
      StoreRevenueCsvDownload();
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      context.commit(SET_LOADING, false);
    }
  },
};

const mutations = {
  [SET_ERROR](state: any, error: any) {
    state.errors = error;
  },
  [SET_LOADING](state: any, loading: boolean) {
    state.loading = loading;
  },
  [SET_UPDATED_DATA](state: any, responseData: any) {
    state.updatedData = responseData;
  },
  [SET_STORE_DETAIL](state: any, responseData: any) {
    state.store = responseData;
  },
  [SET_LIST_STORE](state: any, responseData: any) {
    state.listStores = responseData;
  },
  [SET_LIST_STAFF](state: any, responseData: any) {
    state.listStaffStores = responseData;
  },
  [SET_HISTORY_POINT_USED_STORE](state: any, responseData: any) {
    state.historyPointUsedStore = responseData;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
