import { SET_LOADING } from './../mutations.type';
import { AUTH_CHECK_VALID_TOKEN, RESET_PASSWORD, RESET_PASSWORD_CONFIRM } from './../actions.type';
import JwtService from "@/services/jwt.service";
import { CHECK_AUTH, LOGIN, LOGOUT, CHANGE_EMAIL, CONFIRM_CHANGE_EMAIL, CHANGE_PASSWORD } from "../actions.type";
import { SET_AUTH, SET_ERROR, SET_UPDATED_DATA } from "../mutations.type";
import { ILoginRequest } from "@/services/api/models/students/IStudent";
import { UserChangeEmail, UserChangePassword, UserCheckValidToken, UserConfirmChangeEmail, UserConfirmResetPasswordRequest, UserGetCurrent, UserLogin, UserSendResetPasswordRequest } from "@/services/api/auth.service";
import router from "@/router";
import RoutesNames from "@/router/routesNames";
import { useNotification } from "@kyvg/vue3-notification";

const notification = useNotification();

const state = {
  loading: false,
  errors: null,
  currentUser: null,
  isAuthenticated: !!JwtService.getToken(),
  updatedData: undefined,
};

const getters = {
  getCurrentStudent(state: any) {
    return state.currentUser;
  },
  getCurrentUser(state: any) {
    return state.currentUser;
  },
  getErrors(state: any) {
    return state.errors;
  },
};

const actions = {
  async [CHECK_AUTH](context: { commit: (arg0: string, arg1: any) => void }) {
    try {
      const { data } = await UserGetCurrent();
      context.commit(SET_AUTH, data);
    } catch (response: any) {
      if (response?.showNoti) {
        notification.notify({
          type: "error",
          title: "エラー",
          text: 'ログイン期限が切れました。',
        });
      }
      localStorage.setItem("access_token", "");
      context.commit(SET_AUTH, null);
      context.commit(SET_ERROR, response);
      router.push({ name: RoutesNames.student[0] });
    }
  },
  async [LOGIN](
    context: { commit: (arg0: string, arg1: any) => void },
    credentials: ILoginRequest
  ) {
    try {
      context.commit(SET_LOADING, true);
      const { name, ...body } = credentials;
      const { data } = await UserLogin(body);
      JwtService.saveToken(data.access_token);
      context.commit(SET_LOADING, false);
      context.commit(SET_AUTH, data);
      if (credentials.urlRedirect) return router.push(credentials.urlRedirect);
      else router.push({ name });
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },

  async [LOGOUT](context: { commit: (arg0: string, arg1: any) => void }, name: string) {
    try {
      JwtService.destroyToken();
      context.commit(SET_AUTH, null);
      router.push({ name });
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [CHANGE_EMAIL](context: { commit: (arg0: string, arg1: any) => void }, body: any) {
    try {
      context.commit(SET_LOADING, true);
      await UserChangeEmail(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
      context.commit(SET_LOADING, false);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      context.commit(SET_LOADING, false);
    }
  },

  async [CHANGE_PASSWORD](context: { commit: (arg0: string, arg1: any) => void }, body: any) {
    try {
      context.commit(SET_LOADING, true);
      await UserChangePassword(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
      context.commit(SET_LOADING, false);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      context.commit(SET_LOADING, false);
    }
  },

  async [CONFIRM_CHANGE_EMAIL](context: { commit: (arg0: string, arg1: any) => void }, token: string) {
    try {
      context.commit(SET_LOADING, true);
      const {data} = await UserConfirmChangeEmail(token);
      context.commit(SET_ERROR, null);
      context.commit(SET_LOADING, false);
      return data;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      context.commit(SET_LOADING, false);
    }
  },

  async [RESET_PASSWORD](context: { commit: (arg0: string, arg1: any) => void }, body: any) {
    try {
      context.commit(SET_LOADING, true);
      await UserSendResetPasswordRequest(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_ERROR, null);
      context.commit(SET_LOADING, false);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      context.commit(SET_LOADING, false);
    }
  },

  async [RESET_PASSWORD_CONFIRM](context: { commit: (arg0: string, arg1: any) => void }, body: any) {
    try {
      context.commit(SET_LOADING, true);
      await UserConfirmResetPasswordRequest(body);
      context.commit(SET_ERROR, null);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      context.commit(SET_LOADING, false);
    }
  },

  async [AUTH_CHECK_VALID_TOKEN](context: { commit: (arg0: string, arg1: any) => void }, token: string) {
    try {
      const {data} = await UserCheckValidToken(token);
      context.commit(SET_ERROR, null);
      return data;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
};

const mutations = {
  [SET_ERROR](state: any, error: any) {
    state.errors = error;
  },
  [SET_LOADING](state: any, loading: boolean) {
    state.loading = loading;
  },
  [SET_AUTH](state: any, user: any) {
    state.currentUser = user;
    state.errors = null;
  },
  [SET_UPDATED_DATA](state: any, data: any) {
    state.updatedData = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
