import { toParams } from '@/utils/ObjectUtils';
import ApiInstance from "@/services/api/axiosBase";
import { PATH_URL } from "@/constants";
import { IAddPointForStudentRequest, ICreateAutomaticPointRequest, ICreateStudentRequest, IRefundPointForStudentRequest, IUpdateSchoolValidRequest } from "./models/schools/ISchool";

export const SchoolCreateStudent = (body: ICreateStudentRequest) => {
  return ApiInstance.post(`${PATH_URL.SCHOOL}/create-student`, body);
};

export const SchoolUpdateStudent = (studentId: number, data: any) => {
  return ApiInstance.put(`${PATH_URL.SCHOOL}/student/` + studentId, data);
};

export const SchoolGetStudentDetail = (studentId: number) => {
  return ApiInstance.get(`${PATH_URL.SCHOOL}/student/` + studentId);
};

export const SchoolAddPointForStudent = (body: IAddPointForStudentRequest) => {
  return ApiInstance.post(`${PATH_URL.SCHOOL}/point-for-student`, body);
};

export const SchoolRefundPointForStudent = (body: IRefundPointForStudentRequest) => {
  return ApiInstance.post(`${PATH_URL.SCHOOL}/refund-point`, body);
};

export const SchoolGetFaculties = (query: any) => {
  let params = toParams(query);
  return ApiInstance.get(`${PATH_URL.FACULTY}/list` + params);
};

export const SchoolUpdateStudentStatuses = (data: any) => {
  return ApiInstance.post(`${PATH_URL.SCHOOL}/student/status/update`, data);
};

export const SchoolCreateFaculty = (body: any) => {
  return ApiInstance.post(`${PATH_URL.FACULTY}/create`, body);
};

export const SchoolGetListStaff = (query: any) => {
  let params = new URLSearchParams(query).toString();
  if (params) {
    params = "?" + params;
  } else {
    params = "";
  }
  return ApiInstance.get(`${PATH_URL.SCHOOL}/get-list-staff` + params);
};

export const SchoolImportStaff = (body: any) => {
  return ApiInstance.post(`${PATH_URL.SCHOOL}/import-staff`, body);
};

export const SchoolCreateStaff = (data: any) => {
  const { id = '', confirmPassword, ...body } = data;
  if (!id) return ApiInstance.post(`${PATH_URL.SCHOOL}/create-staff`, body);
  return ApiInstance.put(`${PATH_URL.SCHOOL}/update-staff/${id}`, body);
};

export const SchoolDeleteFaculty = (id: number) => {
  return ApiInstance.delete(`${PATH_URL.FACULTY}/delete/${id}`);
};

export const SchoolGetDetail = (id: number) => {
  return ApiInstance.get(`${PATH_URL.SCHOOL}/${id}`);
};

export const SchoolUpdate = (
  id: number,
  body: any,
) => {
  return ApiInstance.put(`${PATH_URL.SCHOOL}/${id}`, body);
};

export const SchoolGetHistoryPointStudent = (query: any) => {
  let params = new URLSearchParams(query).toString();
  if (params) {
    params = "?" + params;
  } else {
    params = "";
  }
  return ApiInstance.get(`${PATH_URL.SCHOOL}/get-history-point-student` + params);
};

export const SchoolGetHistoryPointUsed = (query: any) => {
  let params = new URLSearchParams(query).toString();
  if (params) {
    params = "?" + params;
  } else {
    params = "";
  }
  return ApiInstance.get(`${PATH_URL.SCHOOL}/get-history-point-used` + params);
};

export const SchoolGetHistoryPointStatuses = () => {
  return ApiInstance.get(`${PATH_URL.SCHOOL}/get-history-point-status`);
};

export const SchoolGetDetailHistoryPoint = (body: any) => {
  const { checkHistory, ...query } = body;
  let params = new URLSearchParams(query).toString();
  if (params) {
    params = "?" + params;
  } else {
    params = "";
  }
  return ApiInstance.get(`${PATH_URL.SCHOOL}/get-detail-history-point/${checkHistory}` + params);
};

export const SchoolGetListAutomaticPoint = (query: any) => {
  let params = new URLSearchParams(query).toString();
  if (params) {
    params = "?" + params;
  } else {
    params = "";
  }
  return ApiInstance.get(`${PATH_URL.SCHOOL}/automatic-point` + params);
};

export const SchoolCreateAutomaticPoint = (body: ICreateAutomaticPointRequest) => {
  return ApiInstance.post(`${PATH_URL.SCHOOL}/automatic-point`, body);
};

export const SchoolGetAutomaticPointDetail = (pointId: number) => {
  return ApiInstance.get(`${PATH_URL.SCHOOL}/automatic-point/` + pointId);
};

export const SchoolUpdateAutomaticPoint = (pointId: number, data: any) => {
  return ApiInstance.put(`${PATH_URL.SCHOOL}/automatic-point/` + pointId, data);
};

export const SchoolDeleteAutomaticPoint = (id: number) => {
  return ApiInstance.get(`${PATH_URL.SCHOOL}/automatic-point/delete/${id}`);
};

export const SchoolImportStudents = (body: any) => {
  return ApiInstance.post(`${PATH_URL.SCHOOL}/import-csv/students`, body, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const SchoolImportStudentsValidation = (body: any) => {
  return ApiInstance.post(`${PATH_URL.SCHOOL}/validate-csv/students`, body, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const SchoolDownloadCSV = () => {
  ApiInstance.get(`${PATH_URL.SCHOOL}/download-csv`, { responseType: 'blob' }).then((response: any) => {
    const href = window.URL.createObjectURL(response);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', '学生作成用テンプレート.csv');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};
