import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import LayoutDefault from "@/components/layouts/LayoutCommon.vue";
import LayoutStore from "@/components/layouts/stores/LayoutStore.vue";
import BaseButton from "@/components/BaseButton.vue";
import LayoutSystem from "@/components/layouts/system/LayoutSystem.vue";
import FontAwesomeIcon from "./fontawesome-icon";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from 'moment-timezone';
import Notifications from '@kyvg/vue3-notification'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import VueMixin from './VueMixin'
import VueSplide from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';
import VueEasyLightbox from 'vue-easy-lightbox';
import "vue-multiselect/dist/vue-multiselect.css"
import { createI18n } from 'vue-i18n'
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';

const defaultLang = "ja";
const i18n = createI18n({
  messages: {
    en: require("./locales/en/index.json"),
    ja: require("./locales/ja/index.json"),
    cn: require("./locales/cn/index.json"),
    kr: require("./locales/kr/index.json"),
    tw: require("./locales/tw/index.json"),
  },
  locale: localStorage.getItem("locale") || defaultLang, // set locale
  fallbackLocale: defaultLang, // set fallback locale
})

const app = createApp(App);
app.mixin(VueMixin);
app.config.isCustomElement = (tag) => tag.startsWith("ion-");
app.config.globalProperties.$moment = moment;
app
	.component('layout-default', LayoutDefault)
	.component('layout-store', LayoutStore)
	.component('layout-system', LayoutSystem)
  .component("VueDatePicker", VueDatePicker)
	.component("font-awesome-icon", FontAwesomeIcon)
	.component("base-button", BaseButton)
  .component('data-table', Vue3EasyDataTable)
  .use(Notifications)
  .use(VueGoogleMaps, {
    load: {
      key: process.env.VUE_APP_MAP_API_KEY,
    },
  })
  .use(VueSplide)
  .use(VueEasyLightbox)
  .use(router)
  .use(store)
  .use(i18n)
  .mount("#app");

