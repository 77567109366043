import { toParams } from "@/utils/ObjectUtils";
import ApiInstance from "./axiosBase";
import { PATH_URL } from "@/constants";

export const StoreCreate = (data: any) => {
  return ApiInstance.post(`${PATH_URL.STORE}/create`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const StoreUpdate = (data: any) => {
  if (!data?.id) return ApiInstance.put(`${PATH_URL.SYSTEM}/store/create`, data);
  return ApiInstance.put(`${PATH_URL.STORE}/update/${data.id}`, data);
};

export const StoreCreateStaff = (data: any) => {
  const { id = '', confirmPassword, ...body } = data;
  if (!id) return ApiInstance.post(`${PATH_URL.STORE}/create-staff`, body);
  return ApiInstance.put(`${PATH_URL.STORE}/update-staff/${id}`, body);
};

export const StoreGetDetail = (id: any) => {
  return ApiInstance.get(`${PATH_URL.STORE}/${id}`);
};

export const StoreGetPreview = (id: any) => {
  return ApiInstance.get(`${PATH_URL.STORE}/preview/${id}`);
};

export const StoreGetList = (query: any) => {
  let params = new URLSearchParams(query).toString();
  if (params) {
    params = "?" + params;
  } else {
    params = "";
  }
  return ApiInstance.get(`${PATH_URL.STORE}/list` + params);
};

export const StoreGetListStaff = (query: any) => {
  let params = new URLSearchParams(query).toString();
  if (params) {
    params = "?" + params;
  } else {
    params = "";
  }
  return ApiInstance.get(`${PATH_URL.STORE}/get-list-staff` + params);
};

export const StoreGetHistoryPointUsed = (query: any) => {
  let params = new URLSearchParams(query).toString();
  if (params) {
    params = "?" + params;
  } else {
    params = "";
  }
	return ApiInstance.get(`${PATH_URL.STORE}/get-history-point-used` + params);
};

export const StoreRefundPoint = (body: any) => {
  return ApiInstance.post(`${PATH_URL.STORE}/refund-point`, body);
};

export const StoreImportStaff = (body: any) => {
  return ApiInstance.post(`${PATH_URL.STORE}/import-staff`, body);
};

export const StoreDownloadCSV = () => {
  ApiInstance.get(`${PATH_URL.STORE}/export-csv-staff`, { responseType: 'blob' }).then((response: any) => {
    const href = window.URL.createObjectURL(response);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'スタッフ作成用テンプレート.csv');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};

export const StoreGetPointReceived = (query: any) => {
  const params = toParams(query);
  return ApiInstance.get(`${PATH_URL.STORE}/get-point-used_by-month${params}`);
};

export const StoreRevenueCsvDownload = () => {
  ApiInstance.get(`${PATH_URL.STORE}/export-csv-point-used`, { responseType: 'blob' }).then((response: any) => {
    const href = window.URL.createObjectURL(response);

    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', '店舗の収益.csv');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
};
