export const transformErrors = (rawErrors: any) => {
  if (typeof rawErrors === 'string') {
    const errors = {
      message: rawErrors
    }

    return errors;
  } else {
    rawErrors.message = rawErrors?.message ||
      Object.values(rawErrors || {})?.[0] ||
      "Something was wrong!"
    return rawErrors;
  }
};
