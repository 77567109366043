import JwtService from "@/services/jwt.service";
import {
  CHECK_VALID_ACCOUNT,
  CHECK_VALID_TOKEN,
  REGISTER_EMAIL,
  REGISTER_PASSWORD,
  STUDENT_CHANGE_EMAIL,
  STUDENT_CHANGE_PASSWORD,
  STUDENT_CONFIRM_CHANGE_EMAIL,
  STUDENT_CONFIRM_RESET_PASSWORD,
  STUDENT_GET_LIST,
  STUDENT_GET_POINT,
  STUDENT_RESET_PASSWORD,
  STUDENT_UPDATE,
  STUDENT_USE_POINT,
  STUDENT_GET_STORE_LIST,
  STUDENT_GET_USED_POINT,
  STUDENT_SEND_FAQ,
  STUDENT_DATA_FAQ,
  STUDENT_DOWNLOAD_CSV,
} from "../actions.type";
import {
  SET_REGISTER_STUDENT,
  SET_ERROR,
  SET_LOADING,
  SET_POINT_STUDENT,
  SET_LIST_STUDENT,
  SET_UPDATED_DATA,
  SET_POINT_USED,
  SET_LIST_STORE,
  SET_USED_POINT_STUDENT,
  SET_SEND_FAQ_BY_STUDENT,
} from "../mutations.type";
import {
  StudentChangeEmail,
  StudentChangePassword,
  StudentCheckValidRegister,
  StudentCheckValidToken,
  StudentConfirmChangeEmail,
  StudentConfirmResetPassword,
  StudentDownloadCSV,
  StudentGetList,
  StudentGetPoint,
  StudentGetUsedPoint,
  StudentRegisterEmail,
  StudentRegisterPassword,
  StudentResetPassword,
  StudentSendFaq,
  StudentStoreList,
  StudentUpdate,
  StudentUsePoint,
} from "@/services/api/students.service";
import {
  IStudentValidRequest,
  IStudentRegisterEmailPassword,
} from "@/services/api/models/students/IStudent";
import router from "@/router";
import { STUDENT_ROUTER_NAMES } from "@/router/groups";

const state = {
  errors: null,
  user: {},
  loading: false,
  isAuthenticated: !!JwtService.getToken(),
  studentRegister: {},
  points: {},
  students: [],
  stores: [],
  usedPoint: [],
  faq: [],
};

const getters = {
  currentStudentRegister(state: any) {
    return state.studentRegister;
  },
};

const actions = {
  async [CHECK_VALID_ACCOUNT](
    context: { commit: (arg0: string, arg1: any) => void },
    credentials: IStudentValidRequest
  ) {
    try {
      const { data } = await StudentCheckValidRegister(credentials);
      context.commit(SET_REGISTER_STUDENT, data);
      router.push({
        name: STUDENT_ROUTER_NAMES.registerEmail
      });
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },

  async [REGISTER_EMAIL](
    context: { commit: (arg0: string, arg1: any) => void },
    credentials: IStudentRegisterEmailPassword
  ) {
    try {
      context.commit(SET_LOADING, true);
      const { data } = await StudentRegisterEmail(credentials);
      context.commit(SET_REGISTER_STUDENT, data);
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
      router.push({
        name: STUDENT_ROUTER_NAMES.registerEmailConfirm
      });
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      context.commit(SET_LOADING, false);
    }
  },

  async [REGISTER_PASSWORD](
    context: { commit: (arg0: string, arg1: any) => void },
    credentials: IStudentRegisterEmailPassword
  ) {
    try {
      context.commit(SET_LOADING, true);
      const { data } = await StudentRegisterPassword(credentials);
      context.commit(SET_REGISTER_STUDENT, data);
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
      router.push({
        name: STUDENT_ROUTER_NAMES.registerCompleted
      });
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      context.commit(SET_LOADING, false);
    }
  },

  async [CHECK_VALID_TOKEN](
    context: { commit: (arg0: string, arg1: any) => void },
    credentials: { token: string }
  ) {
    try {
      await StudentCheckValidToken(credentials);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },

  async [STUDENT_CHANGE_EMAIL](
    context: { commit: (arg0: string, arg1: any) => void },
    credentials: { currentEmail: string; newEmail: string }
  ) {
    try {
      await StudentChangeEmail(credentials);
      router.push({
        name: STUDENT_ROUTER_NAMES.emailChangeSent,
        query: { email: credentials.newEmail }
      });
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },

  async [STUDENT_CONFIRM_CHANGE_EMAIL](
    context: { commit: (arg0: string, arg1: any) => void },
    credentials: string
  ) {
    try {
      context.commit(SET_LOADING, true);
      const {data} = await StudentConfirmChangeEmail(credentials);
      context.commit(SET_ERROR, null);
      context.commit(SET_LOADING, false);
      return data;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      context.commit(SET_LOADING, false);
    }
  },

  async [STUDENT_CHANGE_PASSWORD](
    context: { commit: (arg0: string, arg1: any) => void },
    credentials: {
      currentEmail: string;
      currentPassword: string;
      newPassword: string;
    }
  ) {
    try {
      context.commit(SET_LOADING, true);
      const response = await StudentChangePassword(credentials);
      context.commit(SET_ERROR, null);
      context.commit(SET_LOADING, false);      
      return response;
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [STUDENT_RESET_PASSWORD](
    context: { commit: (arg0: string, arg1: any) => void },
    credentials: { currentEmail: string }
  ) {
    try {
      context.commit(SET_LOADING, true);
      await StudentResetPassword(credentials);
      context.commit(SET_ERROR, null);
      context.commit(SET_LOADING, false);    
      router.push({
        name: STUDENT_ROUTER_NAMES.resetPasswordSuccess
      });
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [STUDENT_CONFIRM_RESET_PASSWORD](
    context: { commit: (arg0: string, arg1: any) => void },
    credentials: { newPassword: string; token: string; email: string }
  ) {
    try {
      const { email, ...body } = credentials;
      await StudentConfirmResetPassword(body);
      context.commit(SET_ERROR, null);
      router.push({
        name: STUDENT_ROUTER_NAMES.resetPasswordChangePassword,
        query: {
          email,
        }
      });
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      router.push({
        name: STUDENT_ROUTER_NAMES.resetPasswordLinkExpired
      });
    }
  },
  async [STUDENT_GET_POINT](
    context: { commit: (arg0: string, arg1: any) => void },
    query: any
  ) {
    try {
      const response = await StudentGetPoint(query);
      context.commit(SET_POINT_STUDENT, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [STUDENT_GET_LIST](
    context: { commit: (arg0: string, arg1: any) => void },
    query: any
  ) {
    try {
      const response = await StudentGetList(query);
      context.commit(SET_LIST_STUDENT, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [STUDENT_UPDATE](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await StudentUpdate(body);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [STUDENT_USE_POINT](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      const { data } = await StudentUsePoint(body);
      context.commit(SET_POINT_USED, data);
      context.commit(SET_ERROR, null);
      context.commit(SET_LOADING, false);
      router.push({
        name: STUDENT_ROUTER_NAMES.usePointSuccess,
        params: {
          id: data.id
        }
      });
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [STUDENT_GET_STORE_LIST](
    context: { commit: (arg0: string, arg1: any) => void },
    query: object
  ) {
    try {
      context.commit(SET_LOADING, true);
      const { data } = await StudentStoreList(query);
      context.commit(SET_LIST_STORE, data);
      context.commit(SET_LOADING, false);
      return data;
    } catch (response: any) {
      context.commit(SET_LOADING, false);
      context.commit(SET_ERROR, response);
    }
  },
  async [STUDENT_GET_USED_POINT](
    context: { commit: (arg0: string, arg1: any) => void },
    query: any
  ) {
    try {
      const response = await StudentGetUsedPoint(query);
      context.commit(SET_USED_POINT_STUDENT, response.data);
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [STUDENT_DATA_FAQ](
    context: { commit: (arg0: string, arg1: any) => void },
    data: any
  ) {
    try {
      context.commit(SET_SEND_FAQ_BY_STUDENT, data);
      router.push({
        name: STUDENT_ROUTER_NAMES.inquiryConfirm,
      });
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
  async [STUDENT_SEND_FAQ](
    context: { commit: (arg0: string, arg1: any) => void },
    body: any
  ) {
    try {
      context.commit(SET_LOADING, true);
      await StudentSendFaq(body);
      context.commit(SET_ERROR, null);
      context.commit(SET_UPDATED_DATA, new Date());
      context.commit(SET_LOADING, false);
      router.push({
        name: STUDENT_ROUTER_NAMES.inquirySent,
      });
    } catch (response: any) {
      context.commit(SET_ERROR, response);
      context.commit(SET_LOADING, false);
    }
  },
  async [STUDENT_DOWNLOAD_CSV](
    context: { commit: (arg0: string, arg1: any) => void },
  ) {
    try {
      StudentDownloadCSV();
      context.commit(SET_ERROR, null);
    } catch (response: any) {
      context.commit(SET_ERROR, response);
    }
  },
};

const mutations = {
  [SET_ERROR](state: any, error: any) {
    state.errors = error;
  },
  [SET_LOADING](state: any, loading: boolean) {
    state.loading = loading;
  },
  [SET_REGISTER_STUDENT](state: any, user: any) {
    state.studentRegister = { ...state.studentRegister, ...user };
    state.errors = null;
  },
  [SET_POINT_STUDENT](state: any, responseData: any) {
    state.points = responseData;
  },
  [SET_LIST_STUDENT](state: any, responseData: any) {
    state.students = responseData;
  },
  [SET_POINT_USED](state: any, responseData: any) {
    state.usePoint = responseData;
  },
  [SET_LIST_STORE](state: any, responseData: any) {
    state.stores = responseData;
  },
  [SET_USED_POINT_STUDENT](state: any, responseData: any) {
    state.usedPoint = responseData;
  },
  [SET_SEND_FAQ_BY_STUDENT](state: any, responseData: any) {
    state.faq = responseData;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
