import student from "./student.module";
import auth from "./auth.module";
import school from "./school.module";
import system from "./system.module";
import store from "./store.module";
import general from "./general.module";
import setting from "./setting.module";

export default {
  student,
  auth,
  school,
  system,
  store,
  general,
  setting,
};
