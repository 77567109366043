import ApiInstance from "@/services/api/axiosBase";
import { PATH_URL } from "@/constants";
import IBank from "./models/generals/IBank";
const BANK_API_KEY = process.env.VUE_APP_BANK_API_KEY;

export const GeneralGetStudentStatuses = () => {
  return ApiInstance.get(`${PATH_URL.GENERAL}/student/status/list`);
};


export const GeneralGetListStoreCategory = (displayFlag: number | null) => {
  return ApiInstance.get(`${PATH_URL.GENERAL}/store/category/list` + (displayFlag ? `?dispFlg=${displayFlag}` : ''));
};

export const GeneralCreateBank = (body: IBank) => {
  return ApiInstance.post(`${PATH_URL.GENERAL}/bank/create`, body);
};

export const GeneralDetailBank = (storeId = null) => {
  let q = '';
  if (storeId) q = `?id=${storeId}`
  return ApiInstance.get(`${PATH_URL.GENERAL}/bank/detail`+q);
};

export const GeneralUpdateBank = (bankId: number, data: any) => {
  return ApiInstance.post(`${PATH_URL.GENERAL}/bank/update/` + bankId, data);
};

export const GenerateExportCsv = async (csvUrl: string, fileName: string = "csv_Data") => {
  ApiInstance.get(csvUrl, { responseType: 'blob' }).then((response: any) => {
    const href = window.URL.createObjectURL(response);
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', fileName + '.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  });
}

export const GeneralSearchBank = (body: any) => {
  return ApiInstance.get(`${PATH_URL.BANK}/banks?apiKey=${BANK_API_KEY}&limit=2000`);
}

export const GeneralSearchBankBranch = (body: any) => {
  return ApiInstance.get(`${PATH_URL.BANK}/banks/${body.bankCode}/branches?apiKey=${BANK_API_KEY}&limit=2000`);
}
