import RoutesNames from '@/router/routesNames';
import PassThroughVue from "@/components/layouts/PassThrough.vue";
import { ACCOUNT_TYPES, ROUTE_ROLES } from '@/constants';

const STORE_ROUTER_NAMES = {
  home: RoutesNames.store[1],
  login: RoutesNames.store[0],
  dashboard: 'store_dashboard',
  resetPassword: 'store_ResetPassword',
  resetPasswordSent: 'store_ResetPasswordSent',
  linkExpired: 'store_LinkExpired',
  linkUsed: 'store_LinkUsed',
  account: 'store_Account',
  emailChange: 'store_EmailChange',
  emailChangeExpired: 'store_emailChangeExpired',
  emailChangeUsed: 'store_emailChangeUsed',
  passwordChange: 'store_passwordChange',
  depositAccount: 'store_depositAccount',
  paymentHistories: 'store_paymentHistories',
  salesReceipt: 'store_salesReceipt',
  detail: 'store_detail',
  staff: 'store_staff',
  info: 'store_info',
  preview: 'preview_info'
}

const storeRouters = {
  path: "/store",
  name: "store_pages",
  component: PassThroughVue,
  children: [
    {
      path: "",
      name: STORE_ROUTER_NAMES.login,
      meta: {
        anonymousOnly: true,
      },
      component: () =>
        import(/* webpackChunkName: "311" */ "@/views/stores/auth/View311.vue"),
    },
    {
      path: "dashboard",
      name: STORE_ROUTER_NAMES.home,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.STORE_ADMIN, ACCOUNT_TYPES.STORE_USER],
        routeRole: ROUTE_ROLES.STORE,
      },
      component: () =>
        import(/* webpackChunkName: "331" */ "@/views/stores/pages/View331.vue"),
    },
    {
      path: "reset-password",
      name: STORE_ROUTER_NAMES.resetPassword,
      meta: {
        anonymousOnly: true,
      },
      component: () =>
        import(/* webpackChunkName: "314" */ "@/views/stores/auth/View314.vue"),
    },
    {
      path: "reset-password/mail-sent",
      name: STORE_ROUTER_NAMES.resetPasswordSent,
      meta: {
        anonymousOnly: true,
      },
      component: () =>
        import(/* webpackChunkName: "315" */ "@/views/stores/auth/View315.vue"),
    },
    {
      path: "reset-password/link-expired",
      name: STORE_ROUTER_NAMES.linkExpired,
      meta: {
        anonymousOnly: true,
      },
      component: () =>
        import(/* webpackChunkName: "316" */ "@/views/stores/auth/View316.vue"),
    },
    {
      path: "reset-password/link-used",
      name: STORE_ROUTER_NAMES.linkUsed,
      meta: {
        anonymousOnly: true,
      },
      component: () =>
        import(/* webpackChunkName: "317" */ "@/views/stores/auth/View317.vue"),
    },
    {
      path: "account",
      name: STORE_ROUTER_NAMES.account,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.STORE_ADMIN],
        routeRole: ROUTE_ROLES.STORE,
      },
      component: () =>
        import(/* webpackChunkName: "351" */ "@/views/stores/pages/View351.vue"),
    },
    {
      path: "email-change",
      name: STORE_ROUTER_NAMES.emailChange,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.STORE_ADMIN],
        routeRole: ROUTE_ROLES.STORE,
      },
      component: () =>
        import(/* webpackChunkName: "353" */ "@/views/stores/pages/View353.vue"),
    },
    {
      path: "email-change/link-expired",
      name: STORE_ROUTER_NAMES.emailChangeExpired,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.STORE_ADMIN],
        routeRole: ROUTE_ROLES.STORE,
      },
      component: () =>
        import(/* webpackChunkName: "356" */ "@/views/stores/pages/View356.vue"),
    },
    {
      path: "email-change/link-used",
      name: STORE_ROUTER_NAMES.emailChangeUsed,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.STORE_ADMIN],
        routeRole: ROUTE_ROLES.STORE,
      },
      component: () =>
        import(/* webpackChunkName: "357" */ "@/views/stores/pages/View357.vue"),
    },
    {
      path: "password-change",
      name: STORE_ROUTER_NAMES.passwordChange,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.STORE_ADMIN],
        routeRole: ROUTE_ROLES.STORE,
      },
      component: () =>
        import(/* webpackChunkName: "358" */ "@/views/stores/pages/View358.vue"),
    },
    {
      path: "deposit-account",
      name: STORE_ROUTER_NAMES.depositAccount,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.STORE_ADMIN],
        routeRole: ROUTE_ROLES.STORE,
      },
      component: () =>
        import(/* webpackChunkName: "359" */ "@/views/stores/pages/View359.vue"),
    },
    {
      path: "payment-histories",
      name: STORE_ROUTER_NAMES.paymentHistories,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.STORE_ADMIN, ACCOUNT_TYPES.STORE_USER],
        routeRole: ROUTE_ROLES.STORE,
      },
      component: () =>
        import(/* webpackChunkName: "371" */ "@/views/stores/pages/View371.vue"),
    },
    {
      path: "sales-receipt",
      name: STORE_ROUTER_NAMES.salesReceipt,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.STORE_ADMIN],
        routeRole: ROUTE_ROLES.STORE,
      },
      component: () =>
        import(/* webpackChunkName: "376" */ "@/views/stores/pages/View376.vue"),
    },
    {
      path: "detail",
      name: STORE_ROUTER_NAMES.detail,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.STORE_ADMIN],
        routeRole: ROUTE_ROLES.STORE,
      },
      component: () =>
        import(/* webpackChunkName: "352" */ "@/views/stores/pages/View352.vue"),
    },
    {
      path: "staff",
      name: STORE_ROUTER_NAMES.staff,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.STORE_ADMIN],
        routeRole: ROUTE_ROLES.STORE,
      },
      component: () =>
        import(/* webpackChunkName: "352" */ "@/views/stores/pages/View360.vue"),
    },
    {
      path: "info",
      name: STORE_ROUTER_NAMES.info,
      meta: {
        requiresAuth: true,
        accountTypes: [ACCOUNT_TYPES.STORE_ADMIN],
        routeRole: ROUTE_ROLES.STORE,
      },
      component: () =>
        import(/* webpackChunkName: "361" */ "@/views/stores/pages/View361.vue"),
    },
    {
      path: "preview/:id",
      name: STORE_ROUTER_NAMES.preview,
      meta: {
        requiresAuth: false,
        accountTypes: [ACCOUNT_TYPES.STORE_ADMIN],
        routeRole: ROUTE_ROLES.STORE,
      },
      component: () =>
        import(
          /* webpackChunkName: "294" */ "@/views/students/pages/View294.vue"
        ),
    },
  ]
};

export {
  storeRouters,
  STORE_ROUTER_NAMES
};
