<template>
  <footer class="content__footer">
    <div class="content__footer--left"></div>
    <div class="content__footer--right"></div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
}
</script>
