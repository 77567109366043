<template>
  <div class="container-fluid p-0">
    <div class="d-flex content-system">
      <Sidebar v-show="sidebar" :isSystem="true"></Sidebar>
      <div :class="{ 'vh-100': !modal }" class="content">
        <Header
          v-show="header"
          :page-title="pageTitle"
          :display-user="displayUser"
          :redirect="redirect"
        ></Header>
        <slot name="body"></slot>
        <slot name="footer"></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/styles/layouts/layout-common.scss";
</style>

<script>
import Sidebar from "@/components/layouts/system/SidebarSystem.vue";
import Header from "@/components/layouts/Header";
import Footer from "@/components/layouts/Footer";
import routesNames from '@/router/routesNames';

export default {
  name: "LayoutSystem",
  components: { Footer, Header, Sidebar },
  props: {
    pageTitle: String,
    sidebar: {
      type: Boolean,
      default: true,
    },
    modal: {
      type: Boolean,
      default: false,
    },
    header: {
      type: Boolean,
      default: true,
    },
    displayUser: {
      type: Boolean,
      default: true,
    },
    redirect: {
      type: String,
      default: routesNames.system[0],
    },
  },
};
</script>
