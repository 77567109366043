<template>
  <div class="btn-group change-language-container" :class="classes">
    <Multiselect
      v-model="lang"
      :options="LANGUAGES"
      :show-labels="false"
      label="title"
      track-by="value"
      :searchable="false"
      :allow-empty="false"
      @select="setLocale"
    >
      <template #option="props">
        <div class="d-flex justify-content-start align-items-center">
          <div>
            <img class="option__image me-2 border" :src="props.option.img" />
          </div>
          <span class="option__title">{{ props.option.title }}</span>
        </div>
      </template>
      <template #singleLabel="props">
        <div class="d-flex justify-content-start align-items-center">
          <div>
            <img class="option__image me-2 border" :src="props.option.img" />
          </div>
          <span class="option__title">{{ props.option.title }}</span>
        </div>
      </template>
    </Multiselect>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";

export default {
  props: {
    classes: {
      type: Object,
      default: [],
    },
  },
  components: {
    Multiselect,
  },
  setup() {
    const selectedLocaleValue = localStorage.getItem("locale");

    const LANGUAGES = [
      {
        title: "JA",
        value: "ja",
        img: require("@/assets/images/flags/JP.svg"),
      },
      {
        title: "EN",
        value: "en",
        img: require("@/assets/images/flags/US.svg"),
      },
      {
        title: "KR",
        value: "kr",
        img: require("@/assets/images/flags/KR.svg"),
      },
      {
        title: "CN",
        value: "cn",
        img: require("@/assets/images/flags/CN.svg"),
      },
      {
        title: "TW",
        value: "tw",
        img: require("@/assets/images/flags/TW.svg"),
      },
    ];
    let selectedLocal;
    if (selectedLocaleValue) {
      selectedLocal = LANGUAGES.find((l) => l.value == selectedLocaleValue);
    } else {
      selectedLocal = LANGUAGES[0];
    }

    return {
      LANGUAGES,
      selectedLocal,
    };
  },
  data() {
    return {
      lang: this.selectedLocal,
    };
  },
  methods: {
    setLocale(locale) {
      localStorage.setItem("locale", locale.value);
      this.$i18n.locale = locale.value;
    },
  },
};
</script>
<style lang="scss" scoped>
.change-language-container {
  position: absolute;
  top: 16px;
  right: 16px;

  &.student-screen {
    top: 0;
    right: 50px;
  }

  .multiselect {
    min-width: 100px;
  }

  .option__image {
    max-width: 24px;
  }
}
</style>
