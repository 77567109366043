export interface IRoutesNames {
  student: string[];
  store: string[];
  system: string[];
  school: string[];
}

const routesNames: Readonly<IRoutesNames> = {
  student: ["studentLogin", "student"],
  store: ["storeLogin", "store"],
  school: ["schoolLogin", "school"],
  system: ["systemLogin", "system"],
};

export default routesNames;
