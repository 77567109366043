<template>
  <router-view></router-view>
  <notifications />
</template>
<script>
export default {
  mounted() {
    document.addEventListener(
      "shown.bs.modal",
      function (event) {
        var modal = event.target;
        var zIndex = 1040 + 10 * document.querySelectorAll(".modal.show").length;
        modal.style.zIndex = zIndex;
        setTimeout(function () {
          var backdrops = document.querySelectorAll(".modal-backdrop:not(.modal-stack)");
          backdrops.forEach(function (backdrop) {
            backdrop.style.zIndex = zIndex - 1;
            backdrop.classList.add("modal-stack");
          });
        }, 0);
      },
      false
    );

    document.addEventListener(
      "hidden.bs.modal",
      function () {
        var zIndex = 1040 + 10 * document.querySelectorAll(".modal.show").length;
        var backdrops = document.querySelectorAll(".modal-backdrop.modal-stack");
        backdrops.forEach(function (backdrop) {
          backdrop.classList.remove("modal-stack");
          backdrop.style.zIndex = zIndex - 1;
        });
      },
      false
    );
  },
};
</script>
<style lang="scss">
@import "@/styles/_common.scss";

#app {
  font-family: "Noto Sans JP", sans-serif, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  ::-webkit-input-placeholder {
    /* Edge */
    color: #ced4da;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ced4da;
  }

  ::placeholder {
    color: #ced4da;
  }
}
</style>
