import { createRouter, createWebHistory, RouteLocation } from "vue-router";
import { Routes } from "./routes";
import { CHECK_AUTH } from "@/store/actions.type";
import store from "@/store";
import jwtService from "@/services/jwt.service";
import { handleRedirect } from "@/utils";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: Routes,
});

const requiresAuthGuard = (
  to: RouteLocation,
  from: RouteLocation,
  next: any
): boolean => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const currentUser = (store as any).state.auth.currentUser;
    const isLoggedIn = (
      (to.meta.accountTypes as any) || []
    ).includes(currentUser?.accountTypes?.cd);
    if (!currentUser) {
      next({
        name: handleRedirect(currentUser, false),
        query: { redirect: to.fullPath },
      });
    } else if (!isLoggedIn) {
      next({
        name: handleRedirect(currentUser, true),
      });
    } else {
      next();
    }
    return true;
  }
  return false;
};

const anonymousOnlyGuard = (
  to: RouteLocation,
  from: RouteLocation,
  next: any
): boolean => {
  if (to.matched.some((record) => record.meta.anonymousOnly)) {
    const currentUser = (store as any).state.auth.currentUser;
    if (!!currentUser) {
      next({
        name: handleRedirect(currentUser, true),
      });
    } else {
      next();
    }
    return true;
  }
  return false;
};

const publicOnlyGuard = (
  to: RouteLocation,
  from: RouteLocation,
  next: any
): boolean => {
  if (to.matched.some((record) => record.meta.public)) {
    next();
    return true;
  }
  return false;
};

router.beforeEach(async (to, from, next) => {
  if (!!jwtService.getToken()) await store.dispatch(CHECK_AUTH);
  if (requiresAuthGuard(to, from, next) || anonymousOnlyGuard(to, from, next) || publicOnlyGuard(to, from, next)) {
    return;
  } else {
    next();
  }
});

export default router;
