import ApiInstance from "@/services/api/axiosBase";
import { PATH_URL } from "@/constants";

export const SettingCreate = (data: any) => {
  return ApiInstance.post(`${PATH_URL.SETTING}/create`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const SettingUpdate = (data: any) => {
  return ApiInstance.put(`${PATH_URL.SETTING}/update/${data.id}`, data);
};

export const SettingGetDetail = (id: any) => {
  return ApiInstance.get(`${PATH_URL.SETTING}/${id}`);
};

export const SettingGetList = () => {
  return ApiInstance.get(`${PATH_URL.SETTING}/list`);
};

export const SettingDelete = (id: number) => {
  return ApiInstance.delete(`${PATH_URL.SETTING}/delete/${id}`);
};
