import axios, { AxiosInstance, AxiosResponse } from "axios";

import HttpStatusCodes from "@/services/common/HttpStatusCodes";
import JwtService from "../jwt.service";
import { useNotification } from "@kyvg/vue3-notification";
import { transformErrors } from "./utils";
import store from "@/store";
import routesNames from "@/router/routesNames";
import { LOGOUT } from "@/store/actions.type";
import { PATH_AUTH_CURRENT_USER } from "./auth.service"
const AuthInterceptor = (config: any): any => {
  const accessToken = JwtService.getToken();
  if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
  return config;
};

const notification = useNotification();

const OnResponseSuccess = (
  response: AxiosResponse<any>
): AxiosResponse<any> => {
  const mes = response.data?.message;
  if (mes) {
    notification.notify({
      type: "success",
      text: mes,
    });
  }
  return response.data;
};

const OnResponseFailure = (error: any): Promise<any> => {
  let checkMes;
  const httpStatus = error?.response?.status;
  const errors = transformErrors(error?.response?.data);

  switch (httpStatus) {
    case HttpStatusCodes.UNAUTHORIZED:
      store.dispatch(LOGOUT, routesNames.student[0]);
      break;
    case HttpStatusCodes.NOT_FOUND:
      break;
    case HttpStatusCodes.FORBIDDEN:
      // router.push({
      //   name: handleRedirect((store as any).state.auth.currentUser, true),
      // });
      break;
    case HttpStatusCodes.UNPROCESSABLE_ENTITY:
      break;
    case HttpStatusCodes.INTERNAL_SEVER:
      break;
    default:
      break;
  }
  if ( error?.response?.data?.message == error?.response?.data?.timestamp) {
    errors.message = "Something was wrong!";
    if (error?.response?.data?.path?.includes(PATH_AUTH_CURRENT_USER)) checkMes = true;
  }
  if (error?.response?.data?.data?.tokenStatus) checkMes = true;
  if (!checkMes)
    notification.notify({
      type: "error",
      title: "エラー",
      text: errors.message,
    });
  return Promise.reject({ ...errors, showNoti: checkMes });
};

const instance: Readonly<AxiosInstance> = axios.create({
  baseURL: process.env.VUE_APP_API_URL || "http://127.0.0.1:8090/api/v1",
  timeout: 30000,
});

instance.defaults.headers.get.Accepts = "application/json";
instance.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

instance.interceptors.request.use(AuthInterceptor);

instance.interceptors.response.use(OnResponseSuccess, OnResponseFailure);

export default instance;
