import RoutesNames from '@/router/routesNames';
import PassThroughVue from "@/components/layouts/PassThrough.vue";
import { ACCOUNT_TYPES, ROUTE_ROLES } from '@/constants';

const SCHOOL_ROUTER_NAMES = {
  home: RoutesNames.school[1],
  login: RoutesNames.school[0],
  staff: "school_staff",
  account: "school_account",
  info: "school_info",
  emailChange: "school_emailChange",
  passwordChange: "school_passwordChange",
  faculty: "school_faculty",
  students: "school_students",
  studentAccounts: "school_studentAccounts",
  studentDetail: "school_studentDetail",
  pointUsed: "school_pointUsed",
  pointAwarded: "school_pointAwarded",
  pointHistories: "school_pointHistories",
  issuedPoints: "school_issuedPoints",
  automaticPointAwarded: "school_automaticPointAwarded",
  pointAward: "school_pointAward",
  pointAwardDetail: "school_pointAwardDetail",
  passwordReset: "school_passwordReset",
  passwordResetMailSent: "school_passwordResetMailSent",
}

const schoolRouters = {
  path: "/school",
  name: "school_pages",
  component: PassThroughVue,
  children: [
    {
      path: "",
      name: SCHOOL_ROUTER_NAMES.login,
      meta: {
        anonymousOnly: true,
        redirect: RoutesNames.school[1],
      },
      component: () =>
        import(
          /* webpackChunkName: "view111" */ "@/views/schools/auth/View111.vue"
        ),
    },
    {
      path: "dashboard",
      name: SCHOOL_ROUTER_NAMES.home,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.school[0],
        routeRole: ROUTE_ROLES.SCHOOL,
        accountTypes: [
          ACCOUNT_TYPES.UNIVERSITY_ADMIN,
          ACCOUNT_TYPES.UNIVERSITY_USER,
        ],
      },
      component: () =>
        import(
          /* webpackChunkName: "view131" */ "@/views/schools/pages/View131.vue"
        ),
    },
    {
      path: "reset-password/mail-sent",
      name: SCHOOL_ROUTER_NAMES.passwordResetMailSent,
      meta: {
        anonymousOnly: true,
        redirect: RoutesNames.school[1],
        routeRole: ROUTE_ROLES.SCHOOL,
      },
      component: () =>
        import(
          /* webpackChunkName: "view113" */ "@/views/schools/auth/View113.vue"
        ),
    },
    {
      path: "reset-password",
      name: SCHOOL_ROUTER_NAMES.passwordReset,
      meta: {
        anonymousOnly: true,
        redirect: RoutesNames.school[1],
        routeRole: ROUTE_ROLES.SCHOOL,
      },
      component: () =>
        import(
          /* webpackChunkName: "view114" */ "@/views/schools/auth/View114.vue"
        ),
    },
    {
      path: "staff",
      name: SCHOOL_ROUTER_NAMES.staff,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.school[0],
        routeRole: ROUTE_ROLES.SCHOOL,
        accountTypes: [
          ACCOUNT_TYPES.UNIVERSITY_ADMIN,
        ],
      },
      component: () =>
        import(
          /* webpackChunkName: "view160" */ "@/views/schools/pages/View160.vue"
        ),
    },
    {
      path: "account",
      name: SCHOOL_ROUTER_NAMES.account,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.school[0],
        routeRole: ROUTE_ROLES.SCHOOL,
        accountTypes: [
          ACCOUNT_TYPES.UNIVERSITY_ADMIN,
        ],
      },
      component: () =>
        import(
          /* webpackChunkName: "view151" */ "@/views/schools/pages/View151.vue"
        ),
    },
    {
      path: "info",
      name: SCHOOL_ROUTER_NAMES.info,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.school[0],
        routeRole: ROUTE_ROLES.SCHOOL,
        accountTypes: [
          ACCOUNT_TYPES.UNIVERSITY_ADMIN,
        ],
      },
      component: () =>
        import(
          /* webpackChunkName: "view152" */ "@/views/schools/pages/View152.vue"
        ),
    },
    {
      path: "email-change",
      name: SCHOOL_ROUTER_NAMES.emailChange,
      meta: {
        redirect: RoutesNames.school[0],
        routeRole: ROUTE_ROLES.SCHOOL,
        requiresAuth: true,
        accountTypes: [
          ACCOUNT_TYPES.UNIVERSITY_ADMIN,
        ],
      },
      component: () =>
        import(
          /* webpackChunkName: "view153" */ "@/views/schools/pages/View153.vue"
        ),
    },
    {
      path: "password-change",
      name: SCHOOL_ROUTER_NAMES.passwordChange,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.school[0],
        routeRole: ROUTE_ROLES.SCHOOL,
        accountTypes: [
          ACCOUNT_TYPES.UNIVERSITY_ADMIN,
        ],
      },
      component: () =>
        import(
          /* webpackChunkName: "view158" */ "@/views/schools/pages/View158.vue"
        ),
    },
    {
      path: "faculty",
      name: SCHOOL_ROUTER_NAMES.faculty,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.school[0],
        routeRole: ROUTE_ROLES.SCHOOL,
        accountTypes: [
          ACCOUNT_TYPES.UNIVERSITY_ADMIN,
        ],
      },
      component: () =>
        import(
          /* webpackChunkName: "view162" */ "@/views/schools/pages/View162.vue"
        ),
    },
    {
      path: "students",
      name: SCHOOL_ROUTER_NAMES.students,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.school[0],
        routeRole: ROUTE_ROLES.SCHOOL,
        accountTypes: [
          ACCOUNT_TYPES.UNIVERSITY_ADMIN,
        ],
      },
      component: () =>
        import(
          /* webpackChunkName: "view171" */ "@/views/schools/pages/View171.vue"
        ),
    },
    {
      path: "student-accounts",
      name: SCHOOL_ROUTER_NAMES.studentAccounts,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.school[0],
        routeRole: ROUTE_ROLES.SCHOOL,
        accountTypes: [
          ACCOUNT_TYPES.UNIVERSITY_ADMIN,
        ],
      },
      component: () =>
        import(
          /* webpackChunkName: "view173" */ "@/views/schools/pages/View173.vue"
        ),
    },
    {
      path: "student/:id",
      name: SCHOOL_ROUTER_NAMES.studentDetail,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.school[0],
        routeRole: ROUTE_ROLES.SCHOOL,
        accountTypes: [
          ACCOUNT_TYPES.UNIVERSITY_ADMIN,
        ],
      },
      component: () =>
        import(
          /* webpackChunkName: "view174" */ "@/views/schools/pages/View174.vue"
        ),
    },
    {
      path: "students/point-used",
      name: SCHOOL_ROUTER_NAMES.pointUsed,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.school[0],
        routeRole: ROUTE_ROLES.SCHOOL,
        accountTypes: [
          ACCOUNT_TYPES.UNIVERSITY_ADMIN,
        ],
      },
      component: () =>
        import(
          /* webpackChunkName: "view175" */ "@/views/schools/pages/View175.vue"
        ),
    },
    {
      path: "students/point-awarded",
      name: SCHOOL_ROUTER_NAMES.pointAwarded,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.school[0],
        routeRole: ROUTE_ROLES.SCHOOL,
        accountTypes: [
          ACCOUNT_TYPES.UNIVERSITY_ADMIN,
          ACCOUNT_TYPES.UNIVERSITY_USER,
        ],
      },
      component: () =>
        import(
          /* webpackChunkName: "view181" */ "@/views/schools/pages/View181.vue"
        ),
    },
    {
      path: "students/point-histories",
      name: SCHOOL_ROUTER_NAMES.pointHistories,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.school[0],
        routeRole: ROUTE_ROLES.SCHOOL,
        accountTypes: [
          ACCOUNT_TYPES.UNIVERSITY_ADMIN,
          ACCOUNT_TYPES.UNIVERSITY_USER,
        ],
      },
      component: () =>
        import(
          /* webpackChunkName: "view184" */ "@/views/schools/pages/View184.vue"
        ),
    },
    {
      path: "issued-points",
      name: SCHOOL_ROUTER_NAMES.issuedPoints,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.school[0],
        routeRole: ROUTE_ROLES.SCHOOL,
        accountTypes: [
          ACCOUNT_TYPES.UNIVERSITY_ADMIN,
          ACCOUNT_TYPES.UNIVERSITY_USER,
        ],
      },
      component: () =>
        import(
          /* webpackChunkName: "view185" */ "@/views/schools/pages/View185.vue"
        ),
    },
    {
      path: "automatic-point-awarded",
      name: SCHOOL_ROUTER_NAMES.automaticPointAwarded,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.school[0],
        routeRole: ROUTE_ROLES.SCHOOL,
        accountTypes: [
          ACCOUNT_TYPES.UNIVERSITY_ADMIN,          
          ACCOUNT_TYPES.UNIVERSITY_USER,
        ],
      },
      component: () =>
        import(/* webpackChunkName: "191" */ "@/views/schools/pages/View191.vue"),
    },
    {
      path: "point-award",
      name: SCHOOL_ROUTER_NAMES.pointAward,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.school[0],
        routeRole: ROUTE_ROLES.SCHOOL,
        accountTypes: [
          ACCOUNT_TYPES.UNIVERSITY_ADMIN,
          ACCOUNT_TYPES.UNIVERSITY_USER,
        ],
      },
      component: () =>
        import(/* webpackChunkName: "193" */ "@/views/schools/pages/View193.vue"),
    },
    {
      path: "point-award/:id",
      name: SCHOOL_ROUTER_NAMES.pointAwardDetail,
      meta: {
        requiresAuth: true,
        redirect: RoutesNames.school[0],
        routeRole: ROUTE_ROLES.SCHOOL,
        accountTypes: [
          ACCOUNT_TYPES.UNIVERSITY_ADMIN,
          ACCOUNT_TYPES.UNIVERSITY_USER,
        ],
      },
      component: () =>
        import(/* webpackChunkName: "194" */ "@/views/schools/pages/View194.vue"),
    },
  ]
};

export {
  schoolRouters,
  SCHOOL_ROUTER_NAMES
};
