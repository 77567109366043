<template>
  <button
    :type="type"
    class="btn d-flex justify-content-center align-items-center"
    @click="handleButtonClick"
    :disabled="disabled || loading"
    :class="btnClass"
    :form="formId"
  >
    <span
      v-if="showLoading"
      v-show="loading"
      class="spinner-border spinner-border-sm me-2"
      role="status"
      aria-hidden="true"
    ></span>
    <slot> </slot>
  </button>
</template>
<script>

export default {
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "button",
    },
    showLoading: {
      type: Boolean,
      default: false,
    },
    formId: {
      type: String,
      default: "",
    },
    btnClass: Object,
  },
  created() {},
  computed: {
    loading() {
      return this.$store.state.auth.loading;
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    handleButtonClick() {
      this.$emit("click");
    },
  },
};
</script>
<style lang="scss" scoped>
.btn__blue {
  min-width: 106px;
}
</style>
